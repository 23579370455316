<template>
    <div class="page page-methodology item">
        <section class="header-contents">
            <div class="left">
                <h2 id="research-methodology">Research Methodology</h2>
            </div>
            <div class="right">
                <h4>Contents:</h4>
                <ul>
                    <li><a href="#research-methodology">Research Methodology</a></li>
                    <ul>
                        <li v-for="v in methodology.contents" v-bind:key="v.slug" ><a v-bind:href="'#' + v.slug">{{v.text}}</a></li>
                    </ul>
                    <li v-for="v in glossary.contents.slice(0, 1)" v-bind:key="v.slug" ><a v-bind:href="'#' + v.slug">{{v.text}}</a></li>
                    <ul>
                        <li v-for="v in glossary.contents.slice(1)" v-bind:key="v.slug" ><a v-bind:href="'#' + v.slug">{{v.text}}</a></li>
                    </ul>
                    <li><a href="#metrics">Arms Recipient Country Metrics</a></li>
                    <ul>
                        <li v-for="(metric, metricSlug) in metricList" v-bind:key="metricSlug" ><a v-bind:href="'#' + metricSlug">{{metric.label}}</a></li>
                    </ul>
                </ul>
            </div>
        </section>
        
        <section v-html="methodology.html" />

        <section v-html="glossary.html" />

        <section>
            <div class="left">
                <h2 id="metrics">Arms Recipient Country Metrics</h2>
                <scroll-top />
            </div>
            <div class="right">
            </div>
            
            <template
                v-for="(metric, metricSlug) in metricList"
                
            >
                <div class="left" :key="metricSlug + 'Left'">
                    <h3 v-bind:id="metricSlug">{{metric.label}}</h3>
                    <scroll-top />
                </div>
                <div class="right" :key="metricSlug + 'Right'">
                    <div class="description">
                        <p>{{metric.desc}}</p>
                    </div>
                    <div v-if="metric.methodology" class="methodology">
                        <p>{{metric.methodology}}</p>
                    </div>
                    <div class="notes" v-if="metric.notes">
                        <p v-html="metric.notes" />
                    </div>
                    <div class="ignore-supplier-note" v-if="metric.ignoreSuppliers">
                        <p v-html="metric.ignoreSupplierNote" />
                        <p>Thus, it was not considered for the following suppliers:</p>
                        <ul
                            v-for="supplierSlug in metric.ignoreSuppliers"
                            v-bind:key="supplierSlug"
                        >
                            <li>{{stateList[supplierSlug].label}}</li>
                        </ul>
                    </div>
                </div>
            </template>
        </section>
        
        <section>
            <div class="left">
                <h2 id="disclaimer">Disclaimer</h2>
                <scroll-top />
            </div>
            <div class="right">
                <div v-html="disclaimer" />
            </div>
        </section>
    </div>
</template>

<script>
/* eslint no-unused-vars: ["error", { "argsIgnorePattern": "_$" }] */

 import {marked} from 'marked';
 import methodologyMd from "!raw-loader!../md/methodology.md";
 import glossaryMd from "!raw-loader!../md/glossary.md";
 import disclaimerMd from "!raw-loader!../md/disclaimer.md";

 function sectionHtmlContents (md) {
     var renderer = new marked.Renderer();
     var out = {
         contents: [],
         html: null,
     };

     renderer.heading = function(text, level, raw_, slugger_) {
         var slug = text.toLowerCase().replace(/[^\w]+/g, '-');
         if (text.indexOf("|") !== -1) {
             [slug, text] = text.split(/\s+\|\s+/);
         }
         out.contents.push({
             slug,
             text
         });

         

         return `
</div>
<div class="left">
            <h${level} id="${slug}">
              ${text}
            </h${level}>
            <p><a href="#top"><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="arrow-up" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="svg-inline--fa fa-arrow-up fa-w-14"><path fill="currentColor" d="M34.9 289.5l-22.2-22.2c-9.4-9.4-9.4-24.6 0-33.9L207 39c9.4-9.4 24.6-9.4 33.9 0l194.3 194.3c9.4 9.4 9.4 24.6 0 33.9L413 289.4c-9.5 9.5-25 9.3-34.3-.4L264 168.6V456c0 13.3-10.7 24-24 24h-32c-13.3 0-24-10.7-24-24V168.6L69.2 289.1c-9.3 9.8-24.8 10-34.3.4z" class=""></path></svg> top</a></p>
</div>
<div class="right">
         `;
     };

     var html = marked(md, {
         renderer,
     });
     out.html = html.substr(7) + html.substr(0, 7);

     return out;
 }

 export default {
     metaInfo() {
        return { 
            title: `Methodology | Who Arms War?`,
        };
     },
     props: {
         metricList: Object,
         stateList: Object,
     },
     data () {
         return {
             methodology: sectionHtmlContents(methodologyMd),
             glossary: sectionHtmlContents(glossaryMd),
             disclaimer: marked(disclaimerMd),
         }
     },
 };
</script>

