/*jshint esversion: 6 */

import Vue from 'vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faArrowRight,
  faArrowUp,
  faMousePointer,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(
  faArrowRight,
  faArrowUp,
  faMousePointer,
  faTimes,
);

Vue.component('font-awesome-icon', FontAwesomeIcon);
