<template>
    <div class="page list">
        <section>
            <div class="left">
                <h2>Conflicts</h2>
                <p>&nbsp;</p>
                <h4>Analysis of 32 wars this century, and which states supplied arms to each of their participants</h4>
            </div>
            <div class="right">
                <div class="figure">
                    <video-youtube
                        id="ZJBfibMO76g"
                        title="Exploring Conflicts"
                    />
                    <p>Lead researcher, <span class="no-wrap">Sam Perlo-Freeman</span>.</p>
                </div>
            </div>
        </section>

        <section>
            <div class="block-container">
                <div class="block-item" v-for="(conflict, conflictSlug) in conflictList" v-bind:key="conflictSlug">
                <router-link v-bind:to="'/conflict/' + conflictSlug">
                    <div><h4>{{conflict.label || conflict.title}}</h4></div>
                    <img class="figure-transfers" :src="require('@/assets/svg/thumbnail/transfer/' + conflictSlug + '.svg')" />
                </router-link>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
 export default {
     metaInfo() {
        return { 
            title: `Conflicts | Who Arms War?`,
        };
     },
     props: {
         conflictList: Object,
     },
 };
</script>

<style lang="scss">
</style>
