<template>
    <svg
        xmlns:svg="http://www.w3.org/2000/svg"
        xmlns="http://www.w3.org/2000/svg"
        class="figure figure-category"
        ref="t1"
        preserveAspectRatio="xMinYMin meet"
        v-bind:viewBox="svgViewbox()"
        v-bind:class="thumbnail ? 'thumbnail' : ''"
        x="0"
        y="0"
        v-bind:width="svg.width || 1"
        v-bind:height="svg.height || 1"
    >
    </svg>
</template>

<script>
 /* eslint no-unused-vars: ["error", { "argsIgnorePattern": "_$" }] */

 import _ from 'lodash';
 import * as d3 from 'd3';
 import common from '../common.js';

 export default {
     props: {
         thumbnail: Boolean,

         categoryData: Object,
     },
     data () {
         return {
             graph: null,
             svg: {
                 elWidthFull: 1024,
                 elWidthThumb: 420,
                 fontFamily: "Aleo",
                 fontSize: 16,
                 lineHeightMult: 1.25,
                 maxTrue: 3,
                 maxFalse: 1,
                 arrowW1: 0.6,
                 arrowW2: 1.0,
                 arrowNull: 0.5,
                 
                 defaultMarginPercent: 1,

                 width: null,
                 height: null,
                 defaultMargin: null,
                 unitY: null,
                 lineHeight: null,
                 graphMidY: null,
                 graphRestY: null,
                 itemWidth: null,
                 itemMargin: null,
             },
             d3: {
                 svg: null,
             },


         };
     },
     watch: {
         categoryData () {
             this.update();
         },
         selection () {
             this.update();
         },
     },
     methods: {
         svgViewbox () {
             var cnt = this;
             return "0 0 " + (cnt.svg.width || 0) + " " + (cnt.svg.height || 0);
         },
         
         setSelection (supplier, participant) {
             var cnt = this;
             cnt.$emit("set-selection", supplier, participant);

             cnt.updateSvg();
         },

         createSvg () {
             var cnt = this;

             var data = _.cloneDeep(_.values(cnt.categoryData));

             if (cnt.thumbnail) {
                 _.each(data, function (v) {
                     if (v.slug == "united-states-of-america") {
                         v.name = "USA";
                     }
                     if (v.slug == "germany") {
                         v.name = "Ger.";
                     }
                     if (v.slug == "ukraine") {
                         v.name = "Ukr.";
                     }
                 });
             }

             cnt.svg.width = cnt.thumbnail ? cnt.svg.elWidthThumb : cnt.svg.elWidthFull;

             cnt.svg.defaultMargin = cnt.svg.width * cnt.svg.defaultMarginPercent / 100;
             cnt.svg.lineHeight = cnt.svg.fontSize * cnt.svg.lineHeightMult;

             cnt.svg.graphWidth = cnt.svg.width - 2 * cnt.svg.defaultMargin;
             cnt.svg.graphHeight = cnt.svg.graphWidth / 6;
             cnt.svg.graphX = cnt.svg.defaultMargin;
             cnt.svg.graphY = cnt.svg.defaultMargin;
             cnt.svg.unitY = cnt.svg.graphHeight / (cnt.svg.maxTrue + 1 + cnt.svg.maxFalse + 1);
             cnt.svg.graphMidY = cnt.svg.graphY + (cnt.svg.maxTrue + 1) * cnt.svg.unitY;
             cnt.svg.graphRestY = (cnt.svg.maxFalse + 1) * cnt.svg.unitY;

             cnt.svg.legendLines = cnt.thumbnail ? 2 : 1;
             cnt.svg.legendY = cnt.svg.graphY + cnt.svg.graphHeight + cnt.svg.defaultMargin;
             cnt.svg.legendHeight = cnt.svg.legendLines * cnt.svg.lineHeight;
             
             cnt.svg.itemMargin = cnt.svg.defaultMargin * 2;
             cnt.svg.itemWidth = cnt.svg.graphWidth / data.length - cnt.svg.itemMargin;
             cnt.svg.height = cnt.svg.legendY + cnt.svg.legendHeight + cnt.svg.defaultMargin;

             var left = cnt.svg.graphX + (cnt.svg.itemWidth + cnt.svg.defaultMargin) / 2;
             _.each(data, function (v) {
                 v.left = left;
                 left += cnt.svg.itemWidth + cnt.svg.itemMargin

                 v.both = (v.true && v.false);
             });

             cnt.d3.svg = d3.select(cnt.$refs["t1"]);
             cnt.d3.svg.html("");

             cnt.d3.svg
                 .append("rect")
                 .attr("class", "layout")
                 .attr("x", cnt.svg.graphX)
                 .attr("y", cnt.svg.graphY)
                 .attr("width", cnt.svg.graphWidth)
                 .attr("height", cnt.svg.graphHeight)
             ;
             
             cnt.d3.svg
                 .append("rect")
                 .attr("class", "layout")
                 .attr("x", cnt.svg.graphX)
                 .attr("y", cnt.svg.legendY)
                 .attr("width", cnt.svg.graphWidth)
                 .attr("height", cnt.svg.legendHeight)
             ;
             
             
             var itemEnter = (cnt.d3.svg)
                 .selectAll("a")
                 .data(data)
                 .enter()
                 .append("a")
                 .attr("href", d => d.url)
                 .attr("transform", d => common.translate(
                     d.left,
                     cnt.svg.graphMidY
                 ))
             ;

             var arrowPoints = function (h) {
                 if (!h) {
                     return "";
                 }

                 var w1 = cnt.svg.arrowW1 / 2;
                 var w2 = cnt.svg.arrowW2 / 2;
                 var p = [
                     [-cnt.svg.itemWidth * w1, 0],
                     [-cnt.svg.itemWidth * w1, cnt.svg.unitY * (-h)],
                     [-cnt.svg.itemWidth * w2, cnt.svg.unitY * (-h)],
                     [0, cnt.svg.unitY * (-h - Math.sign(h))],
                     [cnt.svg.itemWidth * w2, cnt.svg.unitY * (-h)],
                     [cnt.svg.itemWidth * w1, cnt.svg.unitY * (-h)],
                     [cnt.svg.itemWidth * w1, 0],
                 ];

                 return _.map(p, v => v.join(",")).join(" ");
             }
             
             itemEnter
                 .append("polygon")
                 .attr("class", "bar positive")
                 .attr("points", d => arrowPoints(d.true))
             ;
             
             itemEnter
                 .append("polygon")
                 .attr("class", "bar negative")
                 .attr("points", d => arrowPoints(-d.false))
             ;
             
             itemEnter
                 .append("rect")
                 .attr("class", "bar null")
                 .attr("x", cnt.svg.itemWidth * -cnt.svg.arrowW1 / 2)
                 .attr("y", d => cnt.svg.unitY * (-0.5 * cnt.svg.arrowNull * (d.true == 0 && d.false == 0)))
                 .attr("width", cnt.svg.itemWidth * cnt.svg.arrowW1)
                 .attr("height", d => cnt.svg.unitY * (cnt.svg.arrowNull * (d.true == 0 && d.false == 0)))
             ;
             
             itemEnter
                 .append("text")
                 .attr("class", "legend name linkTitle")
                 .attr("y", (d, i) => cnt.svg.graphRestY + cnt.svg.defaultMargin + cnt.svg.lineHeight * (0.5 + (cnt.thumbnail ? (i % 2) : 0)))
                 .attr("font-family", cnt.svg.fontFamily)
                 .attr("text-anchor", "middle")
                 .text(d => d.name)
             ;
             
             itemEnter
                 .append("rect")
                 .attr("class", "clickTarget")
                 .attr("x", -(cnt.svg.itemMargin + cnt.svg.itemWidth) / 2)
                 .attr("y", -cnt.svg.graphMidY)
                 .attr("width", cnt.svg.itemMargin + cnt.svg.itemWidth)
                 .attr("height", cnt.svg.height)
             ;
             
             (cnt.d3.svg)
                 .append("line")
                 .attr("x1", cnt.svg.graphX)
                 .attr("y1", cnt.svg.graphMidY)
                 .attr("x2", cnt.svg.graphX + cnt.svg.graphWidth)
                 .attr("y2", cnt.svg.graphMidY)
             ;

             if (cnt.thumbnail) {
                 (cnt.d3.svg)
                     .append("rect")
                     .attr("class", "tint")
                     .attr("width", cnt.svg.width)
                     .attr("height", cnt.svg.height)
                 ;
             }

         },
         
         update () {
             var cnt = this;
             
             if (_.isNil(cnt.categoryData)) {
                 return;
             }

             cnt.createSvg();
         },
     },
     mounted () {
         var cnt = this;

         cnt.update();
     },
 };
</script>

<style lang="scss">
</style>
