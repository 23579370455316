<template>
    <div class="page page-privacy">
        <div>
        <h1>Privacy Policy</h1>
        <h3>Essential cookies</h3>
        <p>This website contains embedded videos from YouTube which require some cookies to function. We use the “privacy enhanced” mode to avoid sending personal data.</p>
            
        <h3>Optional cookies</h3>
        <p>This website uses Google Analytics cookies to collect usage statistics. This service has been configured to anonymize IP addresses and not share personal data.</p>

        <p>You can accept or decline optional cookes here.</p>

        <template
            v-if="!isPrerender"
        >
            <p>Optional cookies are currently: <span class="gdpr-status">{{gdprStatus}}</span>.</p>
            <div>
                <div
                    class="gdpr-buttons"
                    v-if="gdprAccept !== null"
                >
                    <button
                        class="gdpr-accept"
                        v-on:click="gdpr(true)"
                    >Accept</button>
                    <button
                        class="gdpr-decline"
                        v-on:click="gdpr(false)"
                    >Decline</button>
                </div>
            </div>
        </template>
        
        </div>
    </div>
</template>

<script>
 import common from '../common.js';

 export default {
     metaInfo() {
        return { 
            title: `Privacy | Who Arms War?`,
        };
     },
     data () {
         return {
             gdprAccept: common.gdprAccept,
             isPrerender: !!window.isPrerender,
         };
     },
     computed: {
         gdprStatus () {
             return this.gdprAccept ? "enabled" : "disabled";
         },
     },
     methods: {
         gdpr (value) {
             this.gdprAccept = value;
             this.$parent.$parent.$emit("gdpr", value);
         },
     },
 }
</script>
