/* jshint esversion: 6 */
/* eslint no-unused-vars: ["error", { "argsIgnorePattern": "_$" }] */

import Vue from 'vue';
import App from './App.vue';
import VueRouter from 'vue-router';
import VueMeta from "vue-meta";
import VueCookies from "vue-cookies";
import VueGtag from "vue-gtag";
import { bootstrap } from "vue-gtag";
import _ from 'lodash';
import common from './common.js';

Vue.use(VueRouter);
Vue.use(VueMeta);
Vue.use(VueCookies);
Vue.use(VueGtag, {
  config: {
    id: "G-0KTKE9HJ57",
    params: {
      'anonymize_ip': true,
    },
  },
  bootstrap: false,
});

Vue.config.productionTip = false;
Vue.config.devTools = false;

if (module.hot) {
  module.hot.accept();
  module.hot.addStatusHandler(status => {
    if (status === 'prepare') {
      console.clear();
    }
  });
}

import PageHome from './components/PageHome.vue';
import PageConflict from './components/PageConflict.vue';
import PageConflictList from './components/PageConflictList.vue';
import PageSupplier from './components/PageSupplier.vue';
import PageSupplierList from './components/PageSupplierList.vue';
import PageMethodology from './components/PageMethodology.vue';
import PageAbout from './components/PageAbout.vue';
import PagePrivacy from './components/PagePrivacy.vue';

import FigureTransfers from './components/FigureTransfers.vue';
import FigureSuppliers from './components/FigureSuppliers.vue';
import FigureCategory from './components/FigureCategory.vue';

import './fa.config';

import svgIconWar from '!!raw-loader!./assets/icon-war.svg';
import svgIconMoney from '!!raw-loader!./assets/icon-money.svg';
import svgIconAllies from '!!raw-loader!./assets/icon-allies.svg';
import svgIconOther from '!!raw-loader!./assets/icon-other.svg';

import stateList from "./data/state.json";
import conflictList from "./data/participation.json";
import supplierList from "./data/supplier.definition.json";
import metricList from "./data/metric.definition.json";
import regressionList from "./data/regression.json";
import supply from "./data/transfer.json";
import observationList from "./data/observation/common.json";

delete supplierList["south-korea"];
delete conflictList["central-african-republic"];

Vue.component("scroll-top", {
  template: '<p class="scroll-top"><a href="#top">top <font-awesome-icon icon="arrow-up" /></a></p>'
});

Vue.component("effect-item", {
  template: `
<li>
<font-awesome-icon
    icon="arrow-right"
    class="arrow-increase danger"
    v-if="polarity === true"
/>
<font-awesome-icon
    icon="arrow-right"
    class="arrow-decrease safe"
    v-else-if="polarity === false"
/>
<font-awesome-icon
    icon="arrow-right"
    class="null"
    v-else
/>
<slot />
</li>
`,
  props: {
    polarity: Boolean,
  },
});

Vue.component("video-youtube", {
  template: `<iframe class="youtube" v-bind:src="'https://www.youtube-nocookie.com/embed/' + id" v-bind:title="'YouTube video player: ' + title" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen />`,
  props: {
    id: String,
    title: String,
  },
});

const appConstant = {
  siteTitle: "Who Arms War?",
  yearRange: {
    min: 1990,
    max: 2018,
  },
};

const summaryByCategory = {
  "war": {
    "label": "War",
    "neutralText": "between arms exports to countries at war or during peacetime",
    "icon": svgIconWar,
  },
  "money": {
    "label": "Money",
    "neutralText": "between arms exports to different countries based on economic factors",
    "icon": svgIconMoney,
  },
  "allies": {
    "label": "Relationships",
    "neutralText": "between arms exports to allies and non-allies",
    "icon": svgIconAllies,
  },
  "other": {
    "label": "Other",
    "neutralText": "between arms exports to different countries based on other miscellaneous metrics",
    "icon": svgIconOther,
  },
};

// Data preparation

_.each(metricList, function (v) {
  if (v.hypothesis) {
    var parts = v.hypothesis.split(/\[(.*)\]/);
    if (parts.length > 1) {
      v.hypoPhrase = parts;
      v.hypothesis = parts.join("");
    }
  }
  v.regressions = {};
});

_.each(supplierList, function (v) {
  v.regressions = {};
});

var pThreshold = 0.05;

_.each(regressionList, function (v, k) {
  var [supplier, metric] = k.split("--");
  v.supplier = supplier;
  v.metric = metric;
  v.polarity = null;
  if (v.p < pThreshold) {
    v.polarity = v.coefficient > 0;
  }
});
var regressionList2 = _(regressionList).toPairs().sortBy(function (kv) {
  return _.indexOf(_.keys(supplierList), kv[1].supplier);
}).fromPairs().value();

_.each(regressionList2, function (v, k) {
  metricList[v.metric].regressions[k] = v;
  supplierList[v.supplier].regressions[k] = v;
});

function getSupplyList (conflict, supply) {
  var supplyPeriod = {};

  _.each(conflict.participants, function (participant) {
    if (conflict.showParticipants && !_.includes(conflict.showParticipants, participant.slug)) {
      return;
    }
    _.each(supply, function (supplyData) {
      var [pSlug, sSlug, year, tivValue, finValue] = supplyData;
      if (sSlug == "south-korea") {
        return;
      }
      if (pSlug != participant.slug) {
        return;
      }
      var key = pSlug + "|" + sSlug;
      if (_.isNil(supplyPeriod[key])) {
        supplyPeriod[key] = {
          "s": {
            "slug": sSlug,
          },
          "p": {
            "slug": pSlug,
          },
          "v": 0, // Total TIV value at MAC or War
          "w": 0, // Total TIV value at War
          "1": 0, // Total TIV in “War period” or MAC or WAR between 1990 and 1999 inclusive
          "2": 0, // Total TIV at War from 2000 onward
          "f": 0, // Total financial value at war
          "m": 0, // Max financial value in a single year at War
          "y": {},
        };
      }

      if (participant.intensity[year]) {
        if (!_.isNil(tivValue)) {
          supplyPeriod[key].v += tivValue;

          if (participant.intensity[year] == 2) {
            supplyPeriod[key].w += tivValue;
            if (year >= 2000) {
              supplyPeriod[key]["2"] += tivValue;
            }
            if (!_.isNil(finValue)) {
              supplyPeriod[key].m = Math.max(supplyPeriod[key].m, finValue);
              supplyPeriod[key].f += finValue;
            }
          }

          if (year < 2000) {
            supplyPeriod[key]["1"] += tivValue;
          }
        }

      } else if (
        pSlug == conflict.stateSlug &&
          conflict.warStart <= year && year <= conflict.warEnd &&
          year < 2000 &&
          !_.isNil(tivValue)
      ) {
        supplyPeriod[key]["1"] += tivValue;
      }

      supplyPeriod[key].y[year] = tivValue;

    });
  });

  return _.values(supplyPeriod);
}

function getRegressionSummary() {
  var data = {};
  var supplierDict = {};
  _.each(supplierList, function (v, k) {
    supplierDict[k] = {
      "slug": k,
      "url": '/supplier/' + k,
      "name": stateList[k].short || stateList[k].label,
      "true": 0,
      "false": 0,
      "null": 0,
    };
  });

  _.each(summaryByCategory, function (v, k) {
    data[k] = _.cloneDeep(supplierDict);
  });

  _.each(regressionList, function (v) {
    var c = metricList[v.metric].category;
    data[c][v.supplier][v.polarity] += 1;
  });

  return data;
}

_.each(conflictList, function (v, k) {
  v.supplyList = getSupplyList(v, supply);
  if (_.isNil(v.stateSlug)) {
    v.stateSlug = k;
  }
  // v.List = getSupplyList(v, supply);
});

const siteMap = {
  "conflict": Object.entries(conflictList).map(kv => {
    return {
      "slug": kv[0],
      "label": kv[1].label || kv[1].title,
      "resource": "/conflict/" + kv[0]
    };
  }),
  "supplier": Object.entries(supplierList).map(kv => {
      return {
        "slug": kv[0],
        "label": stateList[kv[0]].label,
        "resource": "/supplier/" + kv[0]
      };
  }),
};

// Routing

const totalTransferData = common.totalTransferData(supplierList, stateList, conflictList);
const regressionSummary = getRegressionSummary();

const routes = [
  {
    path: "",
    component: PageHome,
    props: {
      conflictList: conflictList,
      supplierListK: _.keys(supplierList),
      stateList,
      metricList:  metricList,
      summaryByCategory,
      supply,
      totalTransferData,
      regressionSummary,
    },
  },
  {
    path: "/supplier",
    component: PageSupplierList,
    props: {
      supplierList,
      stateList,
      metricList,
      summaryByCategory,
      totalTransferData,
      regressionSummary,
    },
  },
  {
    path: "/supplier/:supplier",
    component: PageSupplier,
    props: {
      siblingList: siteMap.supplier,
      stateList,
      metricList,
      regressionList: regressionList2,
      observationList,
      conflictList,
      supplierList,
      summaryByCategory,
      supply,
    },
  },
  {
    path: "/conflict",
    component: PageConflictList,
    props: {
      conflictList,
    }
  },
  {
    path: "/conflict/:conflict",
    component: PageConflict,
    props: {
      appConstant,
      siblingList: siteMap.conflict,
      conflictList,
      stateList,
      supplierList: _.keys(supplierList),
      supply,
    }
  },
  {
    path: "/thumbnail/transfer/:conflict",
    component: FigureTransfers,
    props: function (route) {
      return {
        thumbnail: true,
        transferData: common.getTransferData(
          conflictList[route.params.conflict],
          _.keys(supplierList),
          stateList
        ),
      };
    }
  },
  {
    path: "/thumbnail/supplier/total",
    component: FigureSuppliers,
    props: {
      thumbnail: true,
      transferData: totalTransferData,
    }
  },
  {
    path: "/thumbnail/category/:category",
    component: FigureCategory,
    props: function (route) {
      return {
        thumbnail: true,
        categoryData: regressionSummary[route.params.category]
      };
    }
  },
  {
    path: "/methodology",
    component: PageMethodology,
    props: {
      metricList,
      stateList,
    },
  },
  {
    path: "/about",
    component: PageAbout,
  },
  {
    path: "/privacy",
    component: PagePrivacy,
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (to.path == "/supplier") {
      if (from.path == "/supplier") {
        return {
          selector: "#tabs",
          offset: {
            x: 0,
            y: 10,
          },
        };
      } else {
        return {
          x: 0,
          y: 0
        };
      }
    } else if (to.hash) {
      return {
        selector: to.hash,
        offset: {
          x: 0,
          y: 10,
        },
      };
    } else if (savedPosition) {
      return savedPosition;
    } else if (to.path == from.path) {
      // Prevent scroll to top when changing conflict transfer selection;
      return;
    } else {
      return {
        x: 0,
        y: 0
      };
    }
  }
});

function updateGdprAccept(value) {
  var oldValue = common.gdprAccept;
  if (value == true || value == "true") {
    common.gdprAccept = true;
    Vue.$cookies.set("gdprAccept", value);
    bootstrap().then((gtag_) => {
      if (oldValue === false) {
        window.location.reload(true);
      }
    });
  } else if (value == false || value == "false") {
    common.gdprAccept = false;
    _.each(Vue.$cookies.keys(), function (v) {
      Vue.$cookies.remove(v);
    });
    Vue.$cookies.set("gdprAccept", value);
  }
}

updateGdprAccept(Vue.$cookies.get("gdprAccept"));

var v = new Vue({
  render: h => h(App, {
    props: {
      appConstant,
    },
  }),
  router,
}).$mount('#app');

v.$on("gdpr", function (value) {
  updateGdprAccept(value);
});
