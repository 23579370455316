/* jshint esversion: 6 */
/* eslint no-unused-vars: ["error", { "argsIgnorePattern": "_$" }] */

import _ from 'lodash';
import * as d3 from 'd3';

export default {
  gdprAccept: null,

  formatNumber: (n) => d3.format(",.0f")(n),

  formatAbbreviate (n) {

    // SIPRI TIV
    n *= 1000;

    var p = null;
    var levels = [
      ["tn", 12],
      ["bn", 9],
      ["m", 6],
      ["k", 3]
    ];

    if (n === null) {
      return null;
    }

    _.each(levels, function (level) {
      var suffix = level[0];
      var v = level[1];

      if (n >= Math.pow(10, v + 1)) {
        p = n / Math.pow(10, v);
      } else if (n + 5 * Math.pow(10, v - 2) >= Math.pow(10, v + 1)) {
        p = (n + 5 * Math.pow(10, v - 2)) / Math.pow(10, v);
      }

      if (p) {
        p = p.toFixed(0) + suffix;
        return false;
      }

      if (n >= Math.pow(10, v)) {
        p = n / Math.pow(10, v);
      } else if (n + 5 * Math.pow(10, v - 4) >= Math.pow(10, v)) {
        p = (n + 5 * Math.pow(10, v - 4)) / Math.pow(10, v);
      }

      if (p) {
        p = p.toFixed(1) + suffix;
        return false;
      }
    });

    return p || ("" + n);
  },

  translate: (x, y) => "translate(" + x + ", " + y + ")",

  selectionKey: (supplier, participant) => (
    (supplier || "") + "--" + (participant || "")
  ),

  getTransferData (conflict, supplierList, stateList) {
    var common = this;

    var n = 0;
    var stateLookup = {};

    var pSlugList = _.map(conflict.participants, "slug");
    var supplierNull = [];
    var participantNull = [];
    var participantSlugSet = {};

    var data = {
      nodes: [],
      links: [],
      total: {},
    };
    data.nodes = [];
    data.links = [];

    data.nodes.push({
      "node": n,
      "slug": "",
      "name": "",
      "class": "participant",
      "value": 0,
      "transferValue": 0,
    });

    n += 1;

    _.each(conflict.participants, function (participant) {
      if (conflict.showParticipants && !_.includes(conflict.showParticipants, participant.slug)) {
        return;
      }
      var match = _.find(conflict.supplyList, function (supply) {
        return supply.p.slug == participant.slug && supply.w && _.includes(supplierList, supply.s.slug);
      });
      stateLookup["p-" + participant.slug] = n;
      data.nodes.push({
        "node": n,
        "slug": participant.slug,
        "name": stateList[participant.slug].label,
        "class": "participant",
        "value": 0,
        "transferValue": 0,
      });
      participantSlugSet[participant.slug] = true;
      n += 1;
      if (!match) {
        participantNull.push(participant.slug);
      }
    });

    _.each(supplierList, function (supplier) {
      var match = _.find(conflict.supplyList, function (supply) {
        return supply.s.slug == supplier && supply.w && _.includes(pSlugList, supply.p.slug);
      });
      stateLookup["s-" + supplier] = n;
      data.nodes.push({
        "node": n,
        "slug": supplier,
        "name": stateList[supplier].label,
        "class": "supplier" + (_.has(participantSlugSet, supplier) ? " supplier-participant" : ""),
        "value": 0,
        "transferValue": 0,
      });
      n += 1;
      if (!match) {
        supplierNull.push(supplier);
      }
    });

    var addValue = function (supplier, participant, value) {
      var key = common.selectionKey(supplier, participant);
      data.total[key] = (data.total[key] || 0) + value;
    };

    _.each(conflict.supplyList, function (supply) {
      if (supply.w) {
        var source = stateLookup["s-" + supply.s.slug];
        var target = stateLookup["p-" + supply.p.slug];
        data.links.push({
          "source": source,
          "target": target,
          "value": supply.w,
          "transferValue": supply.w,
        });
        data.nodes[source].transferValue += supply.w;
        data.nodes[target].transferValue += supply.w;

        addValue(supply.s.slug, supply.p.slug, supply.w);
        addValue(supply.s.slug, null, supply.w);
        addValue(null, supply.p.slug, supply.w);
        addValue(null, null, supply.w);
      }
    });

    _.each(supplierNull, function (slug) {
      var source = stateLookup["s-" + slug];
      var target = 0;
      data.links.push({
        "source": source,
        "target": target,
        "value": 0,
      });
    });
    _.each(participantNull, function (slug) {
      var source = 0;
      var target = stateLookup["p-" + slug];
      data.links.push({
        "source": source,
        "target": target,
        "value": 0,
      });
    });

    if (supplierNull.length == _.size(supplierList)) {
      console.warn("No supply values during war years in conflict:", conflict.title);
    }

    // Set lower limit on `value` (display value)
    // leaving `transferValue` with the precise value:
    var grandTotal = data.total[common.selectionKey(null, null)];
    var minValue = grandTotal ? grandTotal * 0.01 : 1;
    _.each(data.links, function (v) {
      v.value = Math.max(minValue, v.value);
    });

    return data;
  },

  totalTransferData (supplierList, stateList, conflictList) {
    var data = {};

    _.each(supplierList, function (supplierData, supplier) {
      data[supplier] = {
        "slug": supplier,
        "name": stateList[supplier].short || stateList[supplier].label,
        "targetDict": {},
        "conflictDict": {},
        "value": 0,
        "transferValue": 0,
        "target": {},
      };
    });

    // STUPID start
    _.each(conflictList, function(conflict) {
      _.each(conflict.supplyList, function (supply) {
        if (supply.w) {
          _.each(supply.y, function (w, y) {
            if (w) {
              var k = supply.p.slug + "-" + y;
              if (_.has(data[supply.s.slug].target, k)) {
                console.assert(data[supply.s.slug].target[k] == w);
              } else {
                data[supply.s.slug].target[k] = w;
                data[supply.s.slug].value += w;
                data[supply.s.slug].targetDict[supply.p.slug] = true;
                data[supply.s.slug].conflictDict[conflict.stateSlug] = true;
              }
            }
          });
        }
      });
    });

    _.each(data, function (supplierData) {
      supplierData.transferValue = supplierData.value;
      supplierData.conflictCount = _.size(supplierData.conflictDict);
      supplierData.recipientCount = _.size(supplierData.targetDict);
      delete supplierData.target;
      delete supplierData.conflictDict;
      delete supplierData.targetDict;
    });
    // STUPID end

    return data;
  },

  conflictSupplyLevel (totalData, orders, supplierSlug) {
    var thisOrders = false;
    if (orders) {
      _.each(orders, function (ov, ok_) {
        if (_.has(ov, supplierSlug)) {
          thisOrders = true;
        }
      });
    }

    if (totalData.v > 0) {
      return "mcw";
    } else if (
      totalData["2"] > 50_000 ||
        totalData["1"] > 100_000 ||
        totalData.f > 100_000_000
    ) {
      return "other";
    } else if (
      totalData.m > 1_000_000 ||
        thisOrders
    ) {
      return "other";
    }

    return null;
  },

};
