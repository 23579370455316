<template>
    <div v-if="supplier" class="page page-supplier item">
        <section class="nav">
            <div class="left">
                <router-link to="/supplier"><h3>Top Arms Suppliers</h3></router-link>
            </div>
            <div class="right">
                <nav-siblings
                    resource="/supplier"
                    v-bind:current="supplier"
                    v-bind:siblingList="siblingList"
                />
            </div>
        </section>
        
        <section class="header-contents">
            <div class="left">
                <h2>{{supplierLabel}}</h2>
                <template v-if="supplier=='united-states-of-america'">
                    <p>Despite legislation that asserts a principle of not selling when it might risk escalation or breakout of war, we show that the US has sold weapons to a clear majority of wars</p>
                </template>
                <template v-else-if="supplier=='russia'">
                    <p>Russia sold arms to the most wars of all the top suppliers, and was one of the few countries which was more likely to sell arms to countries that were at war. Russia is party to several global arms control agreements, which in theory should limit sales to conflict zones.</p>
                </template>
                <template v-else-if="supplier=='germany'">
                    <p>Although subject to EU arms controls, our data reveal that Germany sold most arms to the countries that were generally the biggest arms buyers, including when they were at war. It also sold arms to its NATO allies, the UK and US during the Iraq invasion, which it otherwise opposed. 
</p>
                </template>
                <template v-else-if="supplier=='france'">
                    <p>The EU Common Position forbids arms transfers that would “provoke or prolong armed conflicts or aggravate existing tensions or conflicts”. Nonetheless, France armed most of the conflicts that we studied.</p>
                </template>
                <template v-else-if="supplier=='china'">
                    <p>In July 2020, China joined the Arms Trade Treaty, which requires states to consider conflict in their arms export policies. In practice, it tended to arm countries with high miliary acquisition, even when they were engaged in conflict.</p>
                </template>
                <template v-else-if="supplier=='united-kingdom'">
                    <p>The UK code of conduct forbids exports that would “provoke or prolong armed conflicts”. However, the UK supplied arms to the majority of wars that we studied and, after the USA, was the major exporter most involved itself in overseas wars.</p>
                </template>
                <template v-else-if="supplier=='spain'">
                    <p>Despite EU arms controls, Spain armed participants in several of the conflicts we studied, depending more on the demand of recipient countries and on existing relationships.</p>
                </template>
                <template v-else-if="supplier=='israel'">
                    <p>Precluded from the markets of most Arab and Muslim states, Israel was the only country not to sell to Pakistan, and one of the few not to sell to Saudi Arabia or UAE. Nonetheless, Israel still armed a majority of conflicts, and was more likely to sell arms to countries that were involved in minor armed conflict</p>
                </template>
                <template v-else-if="supplier=='italy'">
                    <p>Despite EU arms controls, Italy armed participants in several of the conflicts we studied, depending more on the demand of recipient countries and on existing relationships.</p>
                </template>
                <template v-else-if="supplier=='netherlands'">
                    <p>The Netherlands supplied substantial quantities of arms to the generally high-demand countries, including those in conflict. Interestingly, it appeared to break the EU arms embargo against Myanmar in 2017 by supplying a VIP transport aircraft.</p>
                </template>
                <template v-else-if="supplier=='ukraine'">
                    <p>Ukraine is party to the Wassenaar arrangement and has signed but not ratified the Arms Trade Treaty. Ukraine supplied more wars than other lower-tier exporters, and indeed was more likely to supply arms to countries that were at war. This may be a matter of Ukraine’s cheaper, lower-tech arms being more attractive to poorer buyers, but not those able and willing to spend more. </p>
                </template>
                
                <p>Explore the graphics below to see how the likelihood of arms sales <span class="no-wrap">increases <font-awesome-icon icon="arrow-right" class="arrow-increase danger" /></span> or <span class="no-wrap">decreases <font-awesome-icon icon="arrow-right" class="arrow-decrease safe" /></span> with different factors.</p>
            </div>
            <div class="right">
                <h4>Contents:</h4>
                <ul class="contents">
                    <li v-for="(label, slug) in contents" v-bind:key="slug" ><a v-bind:href="'#' + slug">{{label}}</a></li>
                </ul>
            </div>
        </section>
        
        <section class="summary content" id="factors">
            <div class="left">
                <h3>What factors might affect arms exports from {{supplierLabel}}?</h3>
            </div>
            <div class="right">
                <scroll-top />
            </div>
            <div class="summary-list">
                <div
                    class="summary-item" 
                    v-for="(categoryData, categorySlug) in summaryByCategory2"
                    v-bind:key="categorySlug"
                    v-bind:polarity="categoryData.polarity"
                >
                    <div class="summary-label">
                        <div class="summary-icon" v-html="categoryData.icon"/>
                        <div class="summary-title">
                            <h4>{{categoryData.label}}</h4>
                        </div>
                    </div>
                    <div class="summary-metric-list polarity-neutral" v-if="categoryData.polarity == 'neutral'">
                        <p>No significant difference was found {{categoryData.neutralText}}.</p>
                    </div>
                    <div class="summary-metric-list" v-else>
                        <p class="strong">Arms sales from {{supplierLabel}} are:</p>
                        <ul class="effects">
                            <effect-item
                                v-for="(regr, key) in metricSignificant(categoryData.metricList)"
                                v-bind:key="key"
                                v-bind:polarity="regr.polarity"
                            >
                                <div
                                    v-if="metricList[regr.metric].hypoPhrase"
                                >
                                    <template
                                        v-if="regr.polarity"
                                    >More frequent</template>
                                    <template
                                        v-else
                                    >Less frequent</template>
                                    {{metricList[regr.metric].hypoPhrase[0]}}
                                    <router-link v-bind:to="metricResource(regr.metric)">{{metricList[regr.metric].hypoPhrase[1]}}<span v-if="regr.metric == 'gdp'"> ({{gdpVariant}})</span></router-link>
                                    {{metricList[regr.metric].hypoPhrase[2]}}
                                    <a
                                        class="footnote-link"
                                        v-if="footnotes.metric[regr.metric]"
                                        v-bind:href="'#footnote-' + footnotes.metric[regr.metric]"
                                    >{{footnotes.metric[regr.metric]}}</a>
                                </div>
                            </effect-item>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
            
        <section class="conflicts content" id="conflicts">
            <div class="left">
                <h3>Conflicts</h3>
            </div>
            <div class="right">
                <scroll-top />
            </div>
            
            <div class="left">
                <h4>Direct conflict participation</h4>
            </div>
            <div class="right">
                <div v-if="conflictParticipant">
                    <p>{{supplierLabel}} directly participated in the following conflicts:</p>
                    <ul>
                        <li
                            v-for="(value, key) in conflictParticipant"
                            v-bind:key="key" >
                            <router-link
                                v-bind:to="'/conflict/' + key"
                            >{{value.label || value.title}}</router-link>
                        </li>
                    </ul>
                </div>
                <div v-else>
                    <p>{{supplierLabel}} did not directly participate in any of the conflicts analysed in this project</p>
                </div>
            </div>

            <div class="left">
                <h4>Weapons supplies to conflict participants</h4>
            </div>
            <div class="right">
                <div v-if="conflictSupplier">
                    <div v-if="conflictSupplier.mcw">
                        <p>{{supplierLabel}} supplied <a href="/methodology#mcw">Major Conventional Weapons</a> to active participants in the following conflicts:</p>
                        <ul class="inline">
                            <li
                                v-for="key in conflictSupplier.mcw"
                                v-bind:key="key"
                            >
                                <router-link
                                    v-bind:to="'/conflict/' + key"
                                >{{conflictList[key].label || conflictList[key].title}}</router-link>
                            </li>
                        </ul>
                    </div>
                    <div v-if="conflictSupplier.other">
                        <p>{{supplierLabel}} was an <a href="/methodology#other">Other Significant Supplier</a> to active participants in the following conflicts:</p>
                        <ul class="inline">
                            <li
                                v-for="key in conflictSupplier.other"
                                v-bind:key="key"
                            >
                                <router-link
                                    v-bind:to="'/conflict/' + key"
                                >{{conflictList[key].label || conflictList[key].title}}</router-link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>

        <section class="recipients content" id="recipients">
            <div class="left">
                <h3>Top arms recipients</h3>
            </div>
            <div class="right">
                <scroll-top />
                <div>
                    <p>The greatest recipients of <a href="/methodology#mcw">Major Conventional Weapons</a> from {{supplierLabel}} by value* between 1990 and 2018 are as follows:</p>
                    <div class="figure">
                        <figure-recipients
                            v-bind:supplyTotals="supplyTotals"
                        />
                    </div>
                    <p>*The <a href="/methodology#tiv">SIPRI Trend Indicator Value</a> (TIV) is a relative measure of arms transfer value, normalized for inflation and currency.</p>
                </div>
            </div>
        </section>

        <section class="results content" id="results">
            <div class="left">
                <h3>Full results</h3>
                <div>
                    <p>All metrics were investigated using <a href="/methodology#regression">regression analysis</a>. The full results are reproduced here.</p>
                </div>
            </div>
            <div class="right">
                <scroll-top />
                <div class="regression-table">
                    <div class="regression-row head-row">
                        <div>Metric</div>
                        <div class="mid-col">Observed arms sales</div>
                        <div class="last-col">Significance</div>
                    </div>
                    <div
                        class="regression-row"
                        v-for="(regr, key) in regressions"
                        v-bind:key="key"
                    >
                        <div>
                            <span>
                                <router-link
                                    v-bind:to="metricResource(regr.metric)"
                                >{{metricList[regr.metric].label}}<span v-if="regr.metric == 'gdp'"> ({{gdpVariant}})</span></router-link>
                                <a
                                    class="footnote-link"
                                    v-if="footnotes.metric[regr.metric]"
                                    v-bind:href="'#footnote-' + footnotes.metric[regr.metric]"
                                >&nbsp;{{footnotes.metric[regr.metric]}}</a>
                                <a
                                    class="footnote-link"
                                    v-if="footnotes.supplier[regr.metric]"
                                    v-bind:href="'#footnote-' + footnotes.supplier[regr.metric]"
                                >&nbsp;{{footnotes.supplier[regr.metric]}}</a>

                            </span>
                        </div>
                        
                        <div class="mid-col">
                            <span v-if="regr.p >= 0.05"></span>
                            <span class="danger" v-else-if="regr.coefficient > 0">more frequent</span>
                            <span class="safe" v-else>less frequent</span>
                        </div>

                        <div class="last-col">
                            <span class="sig-na" v-if="regr.na">N/A</span>
                            <span class="sig-none" v-else-if="regr.p >= 0.05">None</span>
                            <span class="sig-mid" v-else-if="regr.p >= 0.01">Moderate</span>
                            <span class="sig-high" v-else-if="regr.p >= 0.001">High</span>
                            <span class="sig-very-high" v-else>Very High</span>
                        </div>

                    </div>
                </div>
            </div>
        </section>

        <section
            class="notes content"
            id="footnotes"
            v-if="footnotes.footnotes.length"
        >
            <div class="left">
                <h3>Footnotes</h3>
            </div>
            <div class="right">
                <scroll-top />
                <ul class="footnotes">
                    <li
                        v-for="(text, i) in footnotes.footnotes"
                        v-bind:key="i"
                    >
                        <span class="footnote-index" v-bind:id="'footnote-' + (i + 1)">{{i + 1}}</span>
                        <span class="footnote-text" v-html="text" />
                    </li>
                </ul>
            </div>
        </section>

    </div>
</template>

<script>
 import _ from 'lodash';
 import common from '../common.js';
 import NavSiblings from './NavSiblings.vue';
 import FigureRecipients from './FigureRecipients.vue';


 export default {
     metaInfo() {
        return { 
            title: `${this.supplierLabel} | Who Arms War?`,
        };
     },
     props: {
         siblingList: Array,
         stateList: Object,
         metricList: Object,
         supplierList: Object,
         observationList: Array,
         conflictList: Object,
         regressionList: Object,
         summaryByCategory: Object,
         supply: Array,
     },
     data () {
         return {
             contents: null,

             supplierLabel: null,
             supplier: null,
             supplyTotals: null,
             regressions: [],
             footnotes: null,
             gdpVariant: null,
             summaryByCategory2: null,
         };
     },
     components: {
         NavSiblings,
         FigureRecipients,
     },
     methods: {
         dvLabel () {
             return "Arms sales from " + this.stateList[this.supplier].label;
         },
         metricResource (slug) {
             return "/methodology#" + slug;
         },
         metricPositive () {
             return _(this.regressions)
                 .pickBy(v => v.polarity === true)
                 .toPairs()
                 .sortBy(function (kv) {
                     return kv[1].p;
                 })
                 .fromPairs()
                 .value()
             ;
         },
         metricNegative () {
             return _(this.regressions)
                 .pickBy(v => v.polarity === false)
                 .toPairs()
                 .sortBy(function (kv) {
                     return kv[1].p;
                 })
                 .fromPairs()
                 .value()
             ;
         },
         metricSignificant (whitelist) {
             return _(this.regressions)
                 .pickBy(v => !_.isNil(v.polarity))
                 .pickBy(v => _.includes(whitelist, v.metric))
                 .toPairs()
                 .sortBy(function (kv) {
                     return kv[1].p;
                 })
                 .fromPairs()
                 .value()
             ;
         },
         update (route) {
             var cnt = this;

             cnt.supplier = route.params.supplier;
             cnt.supplierLabel = cnt.stateList[cnt.supplier].label;

             cnt.regressions = {};
             cnt.footnotes = {
                 "footnotes": [],
                 "metric": {},
                 "supplier": {},
             };

             // Demo data:
             cnt.recipients = {
                 "germany": 100,
                 "south-korea": 20,
                 "togo": 2,
             };

             cnt.summaryByCategory2 = _.cloneDeep(cnt.summaryByCategory);

             _.each(cnt.summaryByCategory2, function (v) {
                 _.extend(v, {
                     "polaritySet": [],
                     "metricList": [],
                 });
             });

             _.each(cnt.regressionList, function(v, k) {
                 if (v.supplier == cnt.supplier) {
                     cnt.regressions[k] = v;
                     
                     if (
                         cnt.metricList[v.metric].notes
                     ) {
                         cnt.footnotes.footnotes.push(cnt.metricList[v.metric].notes);
                         cnt.footnotes.metric[v.metric] = _.size(cnt.footnotes.footnotes);
                     }
                     
                     if (
                         _.includes(cnt.metricList[v.metric].ignoreSuppliers, v.supplier)
                     ) {
                         cnt.footnotes.footnotes.push(cnt.metricList[v.metric].ignoreSupplierNote);
                         cnt.footnotes.supplier[v.metric] = _.size(cnt.footnotes.footnotes);
                         cnt.regressions[k]["na"] = cnt.footnotes.supplier[v.metric];
                     }
                     
                     if (v.metric == "gdp") {
                         cnt.gdpVariant = v["per-capita"] ? "per-capita" : "total";
                     }

                     if (!_.isNil(v.polarity)) {
                         var category = cnt.metricList[v.metric].category;
                         if (category) {
                             cnt.summaryByCategory2[category].polaritySet.push(v.polarity);
                             cnt.summaryByCategory2[category].metricList.push(v.metric);
                         }
                     }
                 }
             });

             _.each(cnt.summaryByCategory2, function (v) {
                 v.polaritySet = _.uniq(v.polaritySet);
                 if (_.isEqual(v.polaritySet, [true])) {
                     v.polarity = "positive";
                 } else if (_.isEqual(v.polaritySet, [false])) {
                     v.polarity = "negative";
                 } else if (!_.isEqual(v.polaritySet, [])) {
                     v.polarity = "mixed";
                 } else {
                     v.polarity = "neutral";
                 }
                 delete v.polaritySet;
             });

             cnt.conflictParticipant = _(cnt.conflictList).toPairs().filter(function (kv) {
                 return _(kv[1].participants).map("slug").includes(cnt.supplier);
             }).fromPairs().value();

             var conflictTotals = {};
             cnt.conflictSupplier = {
                 "mcw": [],
                 "other": [],

             };
             _.each(cnt.conflictList, function (conflict, conflictSlug) {
                 conflictTotals[conflictSlug] = {
                     "v": 0, // Total TIV value at MAC or War
                     "w": 0, // Total TIV value at War
                     "1": 0, // Total TIV in “War period” or MAC or WAR between 1990 and 1999 inclusive
                     "2": 0, // Total TIV at War from 2000 onward
                     "f": 0, // Total financial value at War
                     "m": 0, // Max financial value in a single year at War
                 };
                 var pSlugs = _.map(conflict.participants, "slug");
                 _.each(conflict.supplyList, function (supplyData) {
                     if (supplyData.s.slug != cnt.supplier) {
                         return;
                     }
                     if (!_.includes(pSlugs, supplyData.p.slug)) {
                         return;
                     }

                     conflictTotals[conflictSlug].v += supplyData.v;
                     conflictTotals[conflictSlug].w += supplyData.w;
                     conflictTotals[conflictSlug]["1"] += supplyData["1"];
                     conflictTotals[conflictSlug]["2"] += supplyData["2"];
                     conflictTotals[conflictSlug].f += supplyData.f;
                     conflictTotals[conflictSlug].m = (
                       Math.max(conflictTotals[conflictSlug].m, supplyData.m));
                 });
                         
             });


             _.each(conflictTotals, function (totalData, conflictSlug) {
                 var supplyLevel = common.conflictSupplyLevel(totalData, cnt.conflictList[conflictSlug].orders, cnt.supplier);
                 if (supplyLevel) {
                     cnt.conflictSupplier[supplyLevel].push(conflictSlug);
                 }
             });
             
             if (_.isEmpty(cnt.conflictSupplier.mcw)) {
                 cnt.conflictSupplier.mcw = null
             }
             if (_.isEmpty(cnt.conflictSupplier.other)) {
                 cnt.conflictSupplier.other = null
             }
             if (
                 _.isNull(cnt.conflictSupplier.mcw) &&
                 _.isNull(cnt.conflictSupplier.other)
             ) {
                 cnt.conflictSupplier = null
             }
             

             cnt.supplyTotals = {};
             _.each(cnt.supply, function (supplyData) {
                 var [pSlug, sSlug, year, tivValue, finValue] = supplyData;
                 if (sSlug != cnt.supplier) {
                     return;
                 }
                 if (_.isNil(cnt.supplyTotals[pSlug])) {
                     cnt.supplyTotals[pSlug] = {
                         "p": pSlug,
                         "l": cnt.stateList[pSlug].short || cnt.stateList[pSlug].label,
                         "t": 0, // Total TIV value
                         "f": null, // Total financial value
                         "m": 0, // Max TIV value in single year
                         "n": null, // Max financial value in single year
                         "y": {},
                     };
                 }

                 cnt.supplyTotals[pSlug].t += tivValue;
                 cnt.supplyTotals[pSlug].m = Math.max(cnt.supplyTotals[pSlug].m, tivValue);
                 cnt.supplyTotals[pSlug].y[year] = {
                     "t": tivValue,
                 };
                 if (!_.isNil(finValue)) {
                     cnt.supplyTotals[pSlug].f = (cnt.supplyTotals[pSlug].f || 0) + finValue;
                     cnt.supplyTotals[pSlug].n = Math.max(cnt.supplyTotals[pSlug].n || 0, finValue);
                     cnt.supplyTotals[pSlug].y[year].f = finValue
                 }
             });

             cnt.supplyTotals = _(cnt.supplyTotals).values().sortBy(v => -v.t).slice(0, 5).value();

             cnt.conflictParticipant = _.isEmpty(cnt.conflictParticipant) ? null : cnt.conflictParticipant;

             cnt.contents = {
                 "factors": "Factors and weapons transfers",
                 "conflicts": "Conflicts",
                 "recipients": "Top arms recipients",
                 "results": "Full results",
                 "footnotes": "Footnotes",
             }
             if (!cnt.footnotes.footnotes.length) {
                 delete cnt.contents.footnotes;
             }

         }
     },
     mounted () {
         this.update(this.$route);
     },
     beforeRouteUpdate (to, from, next) {
         this.update(to);
         next();
     }
 };
</script>

<style lang="scss">
</style>
