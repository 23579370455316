<template>
    <div class="page page-about">
        <div v-html="markdownContentAbout()" />
        <div>
            <h2>Disclaimer</h2>
        </div>
        <div v-html="markdownContentDisclaimer()" />
    </div>
</template>

<script>
 import {marked} from 'marked';
 import aboutMd from "!raw-loader!../md/about.md";
 import disclaimerMd from "!raw-loader!../md/disclaimer.md";

 export default {
     metaInfo() {
        return { 
            title: `About | Who Arms War?`,
        };
     },
     methods: {
         markdownContentAbout () {
             return marked(aboutMd);
         },
         markdownContentDisclaimer () {
             return marked(disclaimerMd);
         },
     },
 }
</script>
