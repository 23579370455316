<template>
    <div class="figure figure-timeline">
        <svg
            class="figure figure-timeline"
            ref="t1"
            preserveAspectRatio="xMinYMin meet"
            v-bind:viewBox="svgViewbox()"
        >
        </svg>
    </div>
</template>

<script>
 /* eslint no-unused-vars: ["error", { "argsIgnorePattern": "_$" }] */

 import _ from 'lodash';
 import * as d3 from 'd3';
 import common from '../common.js';

 const defaultMargin = 8;

 export default {
     props: {
         conflict: Object,
         stateList: Object,
         yearStart: Number,
         yearEnd: Number,
         
         selectionKey: Function,

         supplier: String,
         participant: String,
         selection: String,
     },
     data () {
         return {
             svg: {
                 width: 512,
                 top: defaultMargin,
                 right: defaultMargin,
                 bottom: defaultMargin,
                 left: defaultMargin,
                 labelWidth: 60,
                 textHeight: 13,
                 textMargin: 6,
                 titleHeight: 15,
                 titleMargin: 15,
                 dotStroke: 1,
                 
                 
                 height: null,
                 timeLeft: null,
                 timeWidth: null,

                 yearWidth: null,
                 dotRadius: null,

                 stateRowHeight: null,

                 intensityTop: null,
                 intensityHeight: 40,
                 transferTop: null,
                 transferHeight: 60,
                 participantTop: null,
                 participantHeight: null,
                 supplierTop: null,
                 supplierHeight: null,
                 
               },
             d3: {
                 svg: null,
             },
             
             maxTransfer: null,
             maxTransferSelected: null,
             aP: null, // If there is more than one participant
             aS: null, // If there is more than one supplier
         };
     },
     watch: {
         conflict () {
             this.update();
         },
         selection () {
             this.update();
         },
     },
     methods: {
         svgViewbox () {
             var cnt = this;
             return "0 0 " + (cnt.svg.width) + " " + (cnt.svg.height || 0);
         },

         setSelection (supplier, participant) {
             var cnt = this;
             cnt.$emit("set-selection", supplier, participant);
         },

         createSvg (data) {
             var cnt = this;
             cnt.svg.timeLeft = cnt.svg.labelWidth + cnt.svg.left + defaultMargin;
             cnt.svg.timeWidth = cnt.svg.width - cnt.svg.timeLeft - cnt.svg.right;

             cnt.svg.yearWidth = cnt.svg.timeWidth / data.years.length;
             cnt.svg.dotRadius = cnt.svg.yearWidth * 0.7 / 2 - cnt.svg.dotStroke / 2;

             cnt.svg.stateRowHeight = cnt.svg.textHeight + cnt.svg.textMargin;

             cnt.svg.intensityTop = (
                 cnt.svg.top +
                 cnt.svg.titleHeight +
                 defaultMargin
             );
             
             cnt.svg.transferTop = (
                 cnt.svg.intensityTop +
                 cnt.svg.intensityHeight +
                 cnt.svg.textHeight +  // Year labels
                 defaultMargin +
                 cnt.svg.titleMargin +
                 cnt.svg.titleHeight +
                 defaultMargin
             );

             cnt.svg.participantTop = (
                 cnt.svg.transferTop +
                 cnt.svg.transferHeight +
                 cnt.svg.textHeight +  // Year labels
                 defaultMargin +
                 cnt.svg.titleMargin +
                 cnt.svg.titleHeight +
                 defaultMargin
             );
             cnt.svg.participantHeight = cnt.svg.stateRowHeight * (data.participants.length + cnt.aP * 0.5);

             if (data.suppliers.length) {
                 cnt.svg.supplierTop = (
                     cnt.svg.participantTop +
                     cnt.svg.participantHeight +
                     cnt.svg.textHeight +  // Year labels
                     defaultMargin +
                     cnt.svg.titleMargin +
                     cnt.svg.titleHeight +
                     defaultMargin
                 );
                 cnt.svg.supplierHeight = cnt.svg.stateRowHeight * (data.suppliers.length + cnt.aS * 0.5);
             } else {
                 cnt.svg.supplierTop = (
                     cnt.svg.participantTop +
                     cnt.svg.participantHeight +
                     defaultMargin
                 );
                 cnt.svg.supplierHeight = 0;
             }

             cnt.svg.height = (
                 cnt.svg.supplierTop +
                 cnt.svg.supplierHeight +
                 cnt.svg.textHeight +  // Year labels
                 cnt.svg.bottom
             );
             var fail = false;
             _.each(cnt.svg, function (v, k) {
                 if (_.isNaN(v) || !_.isNumber(v)) {
                     console.error("SVG value is not a number:", k);
                     fail = true;
                 }
             });
             if (fail) {
                 return;
             }

             cnt.d3.svg = d3.select(cnt.$refs["t1"]);
             cnt.d3.svg.html("");

             cnt.d3.svg
                 .append("rect")
                 .attr("width", cnt.svg.width)
                 .attr("height", cnt.svg.height)
                 .attr("opacity", 0)
                 .on("click", function (e_) {
                     cnt.setSelection(null, null);
                 })
             ;

         },

         updateSvg (data) {
             var cnt = this;

             var ny = data.years.length;

             var yx = i => cnt.svg.timeLeft + (i + 0.5) * cnt.svg.yearWidth;
             var py = i => cnt.svg.participantTop + (i + 0.5 + 0.5 * (cnt.aP && !!i)) * cnt.svg.stateRowHeight;
             var sy = i => cnt.svg.supplierTop + (i + 0.5 + 0.5 * (cnt.aS && !!i)) * cnt.svg.stateRowHeight;

             var maxTransferT = cnt.maxTransferSelected / cnt.maxTransfer;
             var transferY = t => cnt.svg.transferTop + (1 - t) * cnt.svg.transferHeight;
             var intensityY = intensity => cnt.svg.intensityTop + (0.8 - 0.6 * intensity / 2) * cnt.svg.intensityHeight;
             
             var intensityClass = d => d == 2 ? "intensity-war" :
                                       d == 1 ? "intensity-mac" :
                                       d == 0 ? "intensity-low" :
                                       "intensity-null";

             var titleRows = [
                 {
                     y: cnt.svg.intensityTop - defaultMargin,
                     text: "Conflict intensity",
                 },
                 {
                     y: cnt.svg.transferTop - defaultMargin,
                     text: "Value of arms transfers to active participants:",
                 },
                 {
                     y: cnt.svg.participantTop - defaultMargin,
                     text: "Conflict Participants - arms transfers received:",
                 },
             ];

             if (data.suppliers.length) {
                 titleRows.push({
                     y: cnt.svg.supplierTop - defaultMargin,
                     text: "Top Arms Suppliers - arms transfers to active participants:",
                 });
             }

             _.each(titleRows, function (v) {
                 (cnt.d3.svg)
                     .append("text")
                     .attr("x", cnt.svg.timeLeft)
                     .attr("y", v.y - cnt.svg.titleHeight * 0.1)
                     .attr("text-anchor", "start")
                     .attr("font-size", cnt.svg.titleHeight * 0.8)
                     .attr("class", "title")
                     .text(v.text)
                 ;
             });

             _.each(data.transfers, function (transfers, intensity) {
                 intensity == JSON.parse(intensity);

                 var bar = (cnt.d3.svg)
                     .selectAll("rect.bar")
                     .data(transfers)
                 ;
                 bar
                     .enter()
                     .append("rect")
                     .attr("class", "bar")
                     .attr("x", (d_, i) => yx(i) - (cnt.svg.yearWidth - 2) / 2)
                     .attr("width", cnt.svg.yearWidth - 2)
                     .attr("y", d => cnt.svg.transferTop + cnt.svg.transferHeight - d.y * cnt.svg.transferHeight)
                     .attr("height", d => d.h * cnt.svg.transferHeight)
                     .attr("class", intensityClass(intensity))
                 ;
             });

             var drawSelection = 0 < maxTransferT && maxTransferT < 1;
             var legendUp = drawSelection ? Math.max(0, maxTransferT - 0.8) / 2 : 0;
             var legendDown = drawSelection ? Math.max(0, 0.2 - maxTransferT) / 2 : 0;

             _.each([
                 {
                     "y": intensityY(2),
                     "yText": intensityY(2),
                     "text": "War",
                 },
                 {
                     "y": intensityY(1),
                     "yText": intensityY(1),
                     "text": "Minor",
                 },
                 {
                     "y": intensityY(0),
                     "yText": intensityY(0),
                     "text": "Low",
                 },
                 {
                     "y": transferY(1),
                     "yText": transferY(1 + legendUp),
                     "text": common.formatAbbreviate(cnt.maxTransfer) + " TIV*",
                 },
                 {
                     "y": transferY(maxTransferT),
                     "yText": transferY(maxTransferT - legendUp + legendDown),
                     "text": common.formatAbbreviate(cnt.maxTransferSelected) + " TIV*",
                     "draw": drawSelection,
                 },
                 {
                     "y": transferY(0),
                     "yText": transferY(-legendDown),
                     "text": "0",
                 },
             ], function (v) {
                 if (v.draw === false) {
                     return;
                 }

                 (cnt.d3.svg)
                     .append("line")
                     .attr("class", "graph")
                     .attr("x1", cnt.svg.timeLeft)
                     .attr("y1", v.y)
                     .attr("x2", cnt.svg.timeLeft + cnt.svg.timeWidth)
                     .attr("y2", v.y)
                 ;
                 (cnt.d3.svg)
                     .append("line")
                     .attr("class", "legend")
                     .attr("x1", cnt.svg.timeLeft - defaultMargin * 1.5)
                     .attr("x2", cnt.svg.timeLeft - defaultMargin * 0.5)
                     .attr("y1", (v.yText || v.y))
                     .attr("y2", v.y)
                 ;
                 (cnt.d3.svg)
                     .append("text")
                     .attr("x", cnt.svg.timeLeft - defaultMargin * 2)
                     .attr("y", (v.yText || v.y) + cnt.svg.textHeight * 0.25)
                     .attr("text-anchor", "end")
                     .attr("font-size", cnt.svg.textHeight * 0.8)
                     .text(v.text)
                 ;
             });

             // Intensity line graph

             var intensityData = _.values(cnt.conflict.intensity);
             var intensityLineData = [];

             for (var i = 0; i < intensityData.length - 1; i++) {
                 intensityLineData.push({
                     "i1": intensityData[i],
                     "i2": intensityData[i + 1],
                 });
             }
             (cnt.d3.svg)
                 .selectAll("line.intensityLine")
                 .data(intensityLineData)
                 .enter()
                 .append("line")
                 .attr("class", "intensityLine")
                 .attr("opacity", d => (_.isNil(d.i1) || _.isNil(d.i2)) ? 0 : 1)
                 .attr("x1", (d_, i) => yx(i))
                 .attr("x2", (d_, i) => yx(i + 1))
                 .attr("y1", d => intensityY(d.i1))
                 .attr("y2", d => intensityY(d.i2))
             ;
             (cnt.d3.svg)
                 .selectAll("circle.intensityDot")
                 .data(intensityData)
                 .enter()
                 .append("circle")
                 .attr("r", cnt.svg.dotRadius)
                 .attr("opacity", d => _.isNil(d) ? 0 : 1)
                 .attr("class", function (d) {
                     var c = ["intensityDot"];
                     c.push(intensityClass(d));
                     return c.join(" ");
                 })
                 .attr("transform", (d, i) => common.translate(
                     yx(i),
                     intensityY(d)
                 ))
             ;
             

             // Selected row indices:
             var pIndex = _.isNil(data.participant) ? null : data.participant + cnt.aP;
             var sIndex = _.isNil(data.supplier) ? null : data.supplier + cnt.aS;

             _.each([
                 {
                     "data": data.participants,
                     "selected": pIndex,
                     "selector": "g.pLabel",
                     "class": "pLabel",
                     "y": py,
                     "allIndex": cnt.aP ? 0 : null,
                 },
                 {
                     "data": data.suppliers,
                     "selected": sIndex,
                     "selector": "g.sLabel",
                     "class": "sLabel",
                     "y": sy,
                     "allIndex": cnt.aS ? 0 : null,
                 },
             ], function (v) {
                 var label = (cnt.d3.svg)
                     .selectAll(v.selector)
                     .data(v.data)
                 ;
                 var labelEnter = label
                     .enter()
                     .append("g")
                     .attr("class", function (d, i) {
                         var c = ["label", v.class];

                         if (_.isNull(v.selected) || i == v.selected || i == v.allIndex) {
                             c.push("selected");
                         }

                         return c.join(" ");
                     })
                     .attr("transform", (d_, i) => common.translate(
                         cnt.svg.left,
                         v.y(i)
                     ))
                 ;
                 labelEnter
                     .append("text")
                     .attr("x", 0)
                     .attr("y", (cnt.svg.stateRowHeight - cnt.svg.textHeight) / 2)
                     .attr("text-anchor", "start")
                     .attr("font-size", cnt.svg.textHeight * 0.8)
                     .attr("font-weight", (d_, i) => i ? "normal" : "bold")
                     .text(d => d.label)
                 ;
                 labelEnter
                     .append("rect")
                     .attr("x", -defaultMargin / 2)
                     .attr("y", -cnt.svg.stateRowHeight / 2)
                     .attr("width", cnt.svg.labelWidth + defaultMargin)
                     .attr("height", cnt.svg.stateRowHeight)
                     .attr("rx", cnt.svg.stateRowHeight / 4)
                     .attr("class", "select")
                     .on("click", function (e_, d) {
                         if (v.class == "pLabel") {
                             cnt.setSelection(cnt.supplier, d.slug);
                         } else {
                             cnt.setSelection(d.slug, cnt.participant);
                         }
                     })
                 ;
             });

             var labelRows = [
                 cnt.svg.intensityTop + cnt.svg.intensityHeight,
                 cnt.svg.transferTop + cnt.svg.transferHeight,
                 cnt.svg.participantTop + cnt.svg.participantHeight,
             ];
             if (data.suppliers.length) {
                 labelRows.push(cnt.svg.supplierTop + cnt.svg.supplierHeight);
             }

             _.each(labelRows, function (v) {
                 (cnt.d3.svg)
                     .selectAll("g.yLabel")
                     .data(data.years)
                     .enter()
                     .append("text")
                     .attr("class", "yLabel")
                     .attr("transform", (d_, i) => common.translate(
                         yx(i),
                         v + cnt.svg.textHeight,
                     ))
                     .text((d, i) => (i % 2 == 0 ? d : ""))
                     .attr("font-size", cnt.svg.textHeight * 0.8)
                     .attr("text-anchor", "middle")
                 ;
             });

             _.each([
                 {
                     "selector": "g.pDot",
                     "selected": pIndex,
                     "intensity": data.participantYearIntensity,
                     "value": data.participantYearValue,
                     "y": py,
                     "allIndex": cnt.aP ? 0 : null,
                 },
                 {
                     "selector": "g.sDot",
                     "selected": sIndex,
                     "intensity": data.supplierYearIntensity,
                     "value": data.supplierYearValue,
                     "y": sy,
                     "allIndex": cnt.aS ? 0 : null,
                 },
             ], function (v) {
                 var pDot = (cnt.d3.svg)
                     .selectAll(v.selector)
                     .data(v.intensity)
                 ;
                 var pDotEnter = pDot
                     .enter()
                     .append("g")
                     .attr("class", function (d, i) {
                         var c = ["dot", "pDot"];
                         var row = Math.floor(i / ny);

                         c.push(intensityClass(d));

                         if (d && v.value[i]) {
                             c.push("transfer");
                         }

                         if (_.isNull(v.selected) || row == v.selected || row == v.allIndex) {
                             c.push("selected");
                         }

                         return c.join(" ");
                     })
                     .attr("transform", (d_, i) => common.translate(
                         yx(i % ny),
                         v.y(Math.floor(i / ny))
                     ))
                 ;
                 pDotEnter
                     .append("circle")
                     .attr("cx", 0)
                     .attr("cy", 0)
                     .attr("r", function (d) {
                         return cnt.svg.dotRadius * (d + 1 * (!!d)) / 3 + cnt.svg.dotStroke / 2;
                     })
                     .attr("stroke-width", d => cnt.svg.dotStroke * (1 + d * 0.5))
                 ;
             });
             
             
         },
         
         update () {
             var cnt = this;

             if (_.isNil(cnt.conflict)) {
                 return;
             }

             var participants = cnt.conflict.participants;
             if (cnt.conflict.showParticipants) {
                 participants = _.filter(participants, function (p) {
                     return _.includes(cnt.conflict.showParticipants, p.slug);
                 })
             }

             var suppliers = [];
             _.each(cnt.conflict.supplyList, function (v) {
                 if (v.v) {
                     suppliers.push(v.s.slug);
                 }
             });
             suppliers = _.uniq(suppliers);

             var data = {
                 "years": _.range(cnt.yearStart, cnt.yearEnd + 1),
                 "transfers": {
                     1: [],
                     2: [],
                 },
                 "participants": [],
                 "suppliers": [],
                 "participantYearIntensity": [],
                 "participantYearValue": [],
                 "supplierYearIntensity": [],
                 "supplierYearValue": [],
             };

             var ny = data.years.length;
             cnt.aP = participants.length > 1;
             cnt.aS = suppliers.length > 1;

             if (cnt.aP) {
                 data.participants.push({
                     "label": "All",
                     "slug": null,
                 });
             }
             if (cnt.aS) {
                 data.suppliers.push({
                     "label": "All",
                     "slug": null,
                 });
             }

             var allTransfersByYear = [];

             _.each(data.years, function (y) {
                 var value = null;
                 if (cnt.conflict.start <= y && y <= cnt.conflict.end) {
                     value = 0;
                 }
                 allTransfersByYear.push(0);
                 _.each(data.transfers, function (v_, k) {
                     data.transfers[k].push(0);
                 });
                 if (cnt.aP) {
                     data.participantYearIntensity.push(value);
                     data.participantYearValue.push(value);
                 }
                 if (cnt.aS) {
                     data.supplierYearIntensity.push(value);
                     data.supplierYearValue.push(value);
                 }
             });
             
             _.each(participants, function (participant) {
                 var state = cnt.stateList[participant.slug];
                 data.participants.push({
                     "label": state.short || state.label,
                     "slug": participant.slug,
                 })
                 _.each(data.years, function (y, i) {
                     var intensity = null;
                     if (cnt.conflict.start <= y && y <= cnt.conflict.end) {
                         intensity = Number.parseInt(participant.intensity[y] || "0");
                         if (cnt.aP) {
                             data.participantYearIntensity[i] = Math.max(data.participantYearIntensity[i], intensity);
                         }
                     }
                     data.participantYearIntensity.push(intensity);
                     data.participantYearValue.push(0);
                 });
             });

             _.each(suppliers, function (supplier) {
                 var state = cnt.stateList[supplier];
                 data.suppliers.push({
                     "label": state.short || state.label,
                     "slug": supplier,
                 })
                 _.each(data.years, function (y) {
                     var intensity = cnt.conflict.start <= y && y <= cnt.conflict.end ? 0 : null;
                     data.supplierYearIntensity.push(intensity);
                     data.supplierYearValue.push(0);
                 });
             });
             
             var pLookup = _(participants).map(function (v, i) {
                 return [v.slug, i];
             }).fromPairs().value();

             var sLookup = _(suppliers).map(function (v, i) {
                 return [v, i];
             }).fromPairs().value();

             _.each(cnt.conflict.supplyList, function (supplyData) {
                 // if (cnt.supplier && supplyData.s.slug != cnt.supplier) {
                 //     return;
                 // }
                 // if (cnt.participant && supplyData.p.slug != cnt.participant) {
                 //     return;
                 // }
                 
                 var p = pLookup[supplyData.p.slug];
                 var s = sLookup[supplyData.s.slug];
                 
                 _.each(supplyData.y, function (value, year) {
                     if (!value) {
                         return;
                     }
                     var y = year - cnt.yearStart;
                     var ip = ny * (p + 1 * cnt.aP) + y;  // All participants index
                     var is = ny * (s + 1 * cnt.aS) + y;  // All suppliers index
                     var intensity = data.participantYearIntensity[ip];

                     var includeSupplier = (
                         cnt.supplier == null ||
                         cnt.supplier == supplyData.s.slug
                     );
                     var includeParticipant = (
                         cnt.participant == null ||
                         cnt.participant == supplyData.p.slug
                     );

                     if (intensity) {
                         allTransfersByYear[y] += value;
                     }
                     
                     if (includeSupplier && includeParticipant) {
                         data.supplierYearValue[is] = true;
                         data.participantYearValue[ip] = true;
                         if (intensity) {
                             data.transfers[intensity][y] += value;
                         }

                     if (intensity) {
                         if (cnt.aP) {
                             data.participantYearValue[y] += value;
                         }
                         data.supplierYearIntensity[is] = Math.max(data.supplierYearIntensity[is], intensity);
                         if (cnt.aS) {
                             data.supplierYearValue[y] += value;
                             data.supplierYearIntensity[y] = Math.max(data.supplierYearIntensity[y], intensity)
                         }
                     }
                     
                     }
                 });
             });

             var transferKeys = ["2", "1"];

             _.each(transferKeys, function (intensity, i) {
                 _.each(data.years, function (y, yi) {
                     if (intensity) {
                         data.transfers[intensity][yi] = {
                             "v": data.transfers[intensity][yi],
                             "o": 0,
                         };
                         _.each(transferKeys.slice(0, i), function (intensity2) {
                             data.transfers[intensity][yi].o += data.transfers[intensity2][yi].v;
                         });
                     }
                 });
             })

             cnt.maxTransfer = 0;
             cnt.maxTransferSelected = 0;
             _.each(allTransfersByYear, function (v) {
                 cnt.maxTransfer = Math.max(cnt.maxTransfer, v);
             });
             _.each(data.transfers[1], function (v) {
                 var total = v.o + v.v;
                 cnt.maxTransferSelected = Math.max(cnt.maxTransferSelected, total);
             });
             _.each(data.transfers, function (transfers) {
                 _.each(transfers, function (v) {
                     v.h = cnt.maxTransfer ? v.v / cnt.maxTransfer : 0;
                     v.y = cnt.maxTransfer ? (v.v + v.o) / cnt.maxTransfer : 0;
                 });
             });

             data.supplier = cnt.supplier ? sLookup[cnt.supplier] : null;
             data.participant = cnt.participant ? pLookup[cnt.participant] : null;

             cnt.createSvg(data);
             cnt.updateSvg(data);

         },
     },
     mounted () {
         var cnt = this;
         cnt.update();
     },
 };
</script>

<style lang="scss">
</style>
