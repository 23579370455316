<template>
    <div class="page list">
        <section>
            <div class="left">
                <h2>Top Arms Suppliers</h2>
                <p>&nbsp;</p>
                <h4>We examined the top eleven weapons-supplying nations to determine what factors are associated with greater or lesser arms transfers to recipient countries.</h4>
            </div>
            <div class="right">
                <div class="figure">
                    <video-youtube
                        id="tVOGH9xjjX4"
                        title="Top arms-supplying countries"
                    />
                    <p>Lead researcher, <span class="no-wrap">Sam Perlo-Freeman</span>.</p>
                </div>
            </div>
        </section>
        
        <section>
            <div>
                <ul id="tabs" class="tabs">
                    <li
                        v-for="(v, k) in tabs"
                        v-bind:key="k"
                        v-bind:class="[k == hash ? 'current' : '']"
                    >
                        <router-link
                            v-bind:to="'#' + k"
                        >{{v}}</router-link>
                    </li>
                </ul>
            </div>
            <div class="tab-content">
                <p
                    class="hint"
                    v-if="hash == 'total'"
                >Click on the blocks to see more detail on each country</p>
                <p
                    class="hint"
                    v-else
                >Click on the arrows to see more detail on each country</p>
                
                <div
                    v-if="hash == 'total'"
                    class="figure">
                    <figure-suppliers
                        v-bind:transferData="totalTransferData"
                    />
                    <p>Comparative volume of <a href="/methodology#mcw">Major Conventional Weapons</a> transferred from top supplier countries to states actively at war, 1990-2018</p>
                    <p>*The <a href="/methodology#tiv">SIPRI Trend Indicator Value</a> (TIV) is a relative measure of arms transfer value, normalized for inflation and currency.</p>
                </div>
                <div
                    v-else-if="hash == 'conflict'"
                    class="figure">
                    <figure-category
                        v-bind:categoryData="regressionSummary.war"
                    />
                    <p>Summary of observed weapons transfers versus conflict factors</p>
                </div>
                <div
                    v-else-if="hash == 'money'"
                    class="figure">
                    <figure-category
                        v-bind:categoryData="regressionSummary.money"
                    />
                    <p>Summary of observed weapons transfers versus economic factors, such as GDP or military spending</p>
                </div>
                <div
                    v-else-if="hash == 'relationships'"
                    class="figure">
                    <figure-category
                        v-bind:categoryData="regressionSummary.allies"
                    />
                    <p>Summary of observed arms transfers versus relationship factors, such as recent purchases, membership of NATO, or participation in the same conflicts as the seller country</p>
                </div>
            </div>
        </section>
           
        <section>
            <template
                v-if="hash != 'total'"
            >
                <div class="left">
                    <h2>Key findings</h2>
                </div>
                <div class="right findings">
                    <ul class="effects">
                        <template
                            v-if="hash == 'conflict'"
                        >
                            <effect-item v-bind:polarity="true">
                                <p><router-link to="/supplier/russia">Russia</router-link> and <router-link to="/supplier/ukraine">Ukraine</router-link> were more likely to supply weapons to countries that were actively participating in war.</p>
                            </effect-item>
                            <effect-item v-bind:polarity="true">
                                <p><router-link to="/supplier/france">France</router-link> and <router-link to="/supplier/israel">Israel</router-link> were more likely to supply weapons to countries that were actively participating in <router-link to="/methodology#intensity">minor armed conflict</router-link></p>
                            </effect-item>
                            <effect-item v-bind:polarity="true">
                                <p>The <router-link to="/supplier/netherlands">Netherlands</router-link> were more likely to supply weapons to countries that were actively participating in war, but only if the buyer was already a recent customer.</p>
                            </effect-item>
                            <effect-item v-bind:polarity="null">
                                <p>There was no significant evidence of any country selling less weaponry to countries participating in war or minor armed conflict.</p>
                            </effect-item>
                        </template>
                        <template
                            v-else-if="hash == 'money'"
                        >
                            <effect-item v-bind:polarity="true">
                                <p>For all 11 top supplier states, arms exports were higher to countries that were also buying more arms from the rest of the world.</p>
                            </effect-item>
                            <effect-item v-bind:polarity="true">
                                <p>For most countries, arms exports were higher to countries with higher military spending, or with higher GDP or GDP per capita.</p>
                            </effect-item>
                            <effect-item v-bind:polarity="false">
                                <p>However, for <router-link to="/supplier/russia">Russia</router-link>, <router-link to="/supplier/china">China</router-link>, and <router-link to="/supplier/ukraine">Ukraine</router-link>, arms exports were lower to countries with higher GDP per capita, suggesting their target markets are often poorer countries seeking less expensive and hi-tech equipment.</p>
                            </effect-item>
                        </template>
                        <template
                            v-else-if="hash == 'relationships'"
                        >
                            <effect-item v-bind:polarity="true">
                                <p>For all 11 top supplier states, arms exports were higher to countries that were already a recent customer.</p>
                            </effect-item>
                            <effect-item v-bind:polarity="false">
                                <p>For <router-link to="/supplier/russia">Russia</router-link>, <router-link to="/supplier/china">China</router-link>, and <router-link to="/supplier/ukraine">Ukraine</router-link>, arms exports were lower if the recipient was a member of NATO.</p>
                            </effect-item>
                            <effect-item v-bind:polarity="false">
                                <p>Perhaps suprisingly, the <router-link to="/supplier/united-kingdom">United Kingdom</router-link> was less likely to export arms to other countries that participated in the International Security Assistance Force in <router-link to="/conflict/afghanistan">Afghanistan</router-link>, though this result showed only moderate statistical significance.</p>
                            </effect-item>
                        </template>
                    </ul>
                </div>
            </template>
            <template>
                <div class="left">
                    <h2>Find out more</h2>
                    <h4>&nbsp;<br><router-link to="/conflict">See the flow of arms for each war, and how weapons sales evolve over time in our conflict analysis pages.</router-link></h4>
                </div>
                <div class="right">
                    <div class="figure">
                        <router-link to="/conflict">
                            <img src="../assets/somalia-transfers.png" />
                        </router-link>
                    </div>
                </div>
            </template>

        </section>
           
    </div>
</template>

<script>
 import FigureSuppliers from './FigureSuppliers.vue';
 import FigureCategory from './FigureCategory.vue';

 export default {
     metaInfo() {
        return { 
            title: `Top Arms Suppliers | Who Arms War?`,
        };
     },
     props: {
         supplierList: Object,
         stateList: Object,
         metricList: Object,
         summaryByCategory: Object,
         totalTransferData: Object,
         regressionSummary: Object,
     },
     data () {
         return {
             tabs: {
                 "total": "Total Weapons to Warzones",
                 "conflict": "Arms and Conflict",
                 "money": "Arms and Money",
                 "relationships": "Arms and Relationships",
             },
             hash: null,
         };
     },
     components: {
         FigureSuppliers,
         FigureCategory,
     },
     methods: {
         updateHash () {
             var cnt = this;
             var hash = window.location.hash;
             if (!hash) {
                 hash = "total";
             } else {
                 hash = hash.substr(1);
             }
             cnt.hash = hash;
         },
     },
     mounted () {
         this.updateHash();
     },
 };
</script>

<style lang="scss">
</style>
