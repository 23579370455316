<template>
    <div class="nav-siblings">
        <template v-if="index">
            <div class="left">&lt;</div>
            <div class="prev"><router-link v-bind:to="prev.resource">{{prev.label}}</router-link></div>
            <div class="index">{{index}} / {{total}}</div>
            <div class="next"><router-link v-bind:to="next.resource">{{next.label}}</router-link></div>
            <div class="right">&gt;</div>
        </template>
        
    </div>
</template>

<script>
import _ from 'lodash';

 export default {
     props: {
         label: String,
         resource: String,
         siblingList: Array,
         current: String,

     },
     data () {
         return {
             index: null,
         };
     },
     methods: {
         update () {
             var cnt = this;
             var index = _.findIndex(cnt.siblingList, function (v) {
                 return v.slug == cnt.current;
             });
             cnt.total = cnt.siblingList.length;
             if (index >= 0) {
                 var p = index ? index - 1 : cnt.total - 1;
                 var n = (index + 1) % cnt.total;
                 cnt.index = index + 1;
                 cnt.prev = cnt.siblingList[p];
                 cnt.next = cnt.siblingList[n];
             } else {
                 cnt.index = null;
                 delete cnt.prev;
                 delete cnt.next;
             }

         },
     },
     watch: {
         current () {
             this.update();
         },
     },
     mounted () {
         var cnt = this;
         cnt.update();
     },
 };
</script>
