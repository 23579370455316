<template>
    <div
        id="app"
        v-bind:class="{gdprAccept: isPrerender || gdprAccept !== null}"
    >
        <header
            id="app-header"
            v-bind:class="{'home': $route.fullPath == '/'}"
        >
            <div id="top">
                <AppNav
                    v-bind:appConstant="appConstant"
                />
            </div>
        </header>

        <router-view
            id="app-content"
            v-bind:key="$route.fullPath"
        />
        
        <footer id="app-footer">
            <div class="nav">
                <AppNav
                    v-bind:appConstant="appConstant"
                    v-bind:invert="true"
                />
            </div>
        </footer>
        <div
            v-if="gdprAccept === null && !isPrerender"
            id="gdpr-footer"
        >
            <div>
                <div class="gdpr-message">This website uses cookies to collect anonymous visitor statistics. <router-link to="/privacy">Privacy policy</router-link>.</div>
                <div class="gdpr-buttons">
                    <button
                        class="gdpr-accept"
                        v-on:click="gdpr(true)"
                    >Accept</button>
                    <button
                        class="gdpr-decline"
                        v-on:click="gdpr(false)"
                    >Decline</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
 import AppNav from './components/AppNav.vue';
 import common from './common.js';
 
 export default {
     metaInfo() {
        return { 
            title: "Who Arms War?",
        };
     },
     props: {
         appConstant: Object,
     },
     data () {
         return {
             gdprAccept: common.gdprAccept,
             isPrerender: !!window.isPrerender,
         };
     },
     components: {
         AppNav,
     },
     methods: {
         gdpr (value) {
             this.gdprAccept = value;
             this.$parent.$emit("gdpr", value);
         },
     },
 };

</script>

<style lang="scss">
 @import "./src/sass/style.scss";
</style>
