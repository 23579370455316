<template>
    <div v-if="conflict" class="page page-conflict item">
        <section class="nav">
            <div class="left">
                <router-link to="/conflict"><h3>Conflicts</h3></router-link>
            </div>
            <div class="right">
                <nav-siblings
                    resource="/conflict"
                    v-bind:current="conflictKey"
                    v-bind:siblingList="siblingList"
                />
            </div>
        </section>
        
        <section class="header-contents">
            <div class="left">
                <h2>{{conflict.title}}</h2>
                <h3>{{conflict.period}}</h3>
                <p>Here we show which countries transferred weapons to war in {{conflict.title}}, how much, and when.</p>
                <p>Click on the graphics below to filter results to specific countries.</p>
            </div>
            <div class="right">
                <h4>Contents:</h4>
                <ul class="contents">
                    <li v-for="(label, slug) in contents" v-bind:key="slug" ><a v-bind:href="'#' + slug">{{label}}</a></li>
                </ul>
            </div>
        </section>
        
        <section
            class="transfers content"
            id="transfers"
            v-if="hasTransfers()"
        >
            <div class="left">
                <h3>Transfers of Major Conventional Weapons to Participants while at War</h3>
            </div>
            <div class="right">
                <scroll-top />
                <p>Data from the Stockholm International Peace Research Institute.</p>
            </div>
            
            <div class="left figure">
                <figure-transfers
                    v-bind:transferData="transferData"
                    v-bind:supplier="supplier"
                    v-bind:participant="participant"
                    v-bind:selection="selection"
                    v-bind:total="total"
                    @set-selection="setSelection"
                />
            </div>
            <div class="right guide">
                <div class="guide-info">
                    <div class="transfer-selection">
                        <div class="from-to">
                            <div class="from">
                                <div class="def">From</div>
                                <div class="name">{{supplierName()}}</div>
                            </div>
                            <div class="to">
                                <div class="def">To</div>
                                <div class="name">{{participantName()}}</div>
                            </div>
                        </div>
                        <div class="total">
                            <div class="result">
                                <span class="def">Total:</span>
                                <span class="value">{{total}}</span>
                                <span class="unit">TIV*</span>
                            </div>
                        </div>
                    </div>
                    <div class="filter-control">
                        <button
                            class="filter-control-clear"
                            v-if="supplier || participant"
                            v-on:click="setSelection(null, null)"
                        >
                            <font-awesome-icon
                                icon="times"
                            />&nbsp;
                            <span>Clear filters</span>
                        </button>
                        <div
                            v-else
                            class="filter-control-hint"
                        >
                            <font-awesome-icon
                                icon="mouse-pointer"
                            />&nbsp;
                            <span>Select items in the graph to filter results</span>
                        </div>
                        
                    </div>
                </div>
                <div class="guide-notes">
                    <p>*The <a href="/methodology#tiv">SIPRI Trend Indicator Value</a> (TIV) is a relative measure of arms transfer value, normalized for inflation and currency.</p>
                    <p v-if="fullParticipants()">Comparatively minor state participants have been omitted for the visual clarity of the graphic. <router-link to="all-participants">See the full list</router-link>.</p>
                    <div v-if="conflict.otherParticipants">
                        <p>The following state or non-state participants are not included in analysis because no official arms transfer data for them are available:</p>
                        <ul class="inline">
                            <li v-for="label in conflict.otherParticipants" v-bind:key="label" >{{label}}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>

        <section class="timeline content" id="timeline">
            <div class="left">
                <h3>Conflict Timeline</h3>
            </div>
            <div class="right">
                <scroll-top />
            </div>
            
            <div class="left figure">
                <figure-timeline
                    v-bind:conflict="conflict"
                    v-bind:stateList="stateList"
                    v-bind:yearStart="appConstant.yearRange.min"
                    v-bind:yearEnd="appConstant.yearRange.max"
                    v-bind:supplier="supplier"
                    v-bind:participant="participant"
                    v-bind:selection="selection"
                    @set-selection="setSelection"
                />
            </div>
            <div class="right guide">
                <div class="guide-info">
                    <div class="transfer-selection">
                        <div class="from-to">
                            <div class="from">
                                <div class="def">From</div>
                                <div class="name">{{supplierName()}}</div>
                            </div>
                            <div class="to">
                                <div class="def">To</div>
                                <div class="name">{{participantName()}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="filter-control">
                        <button
                            class="filter-control-clear"
                            v-if="supplier || participant"
                            v-on:click="setSelection(null, null)"
                        >
                            <font-awesome-icon
                                icon="times"
                            />&nbsp;
                            <span>Clear filters</span>
                        </button>
                        <div
                            v-else
                            class="filter-control-hint"
                        >
                            <font-awesome-icon
                                icon="mouse-pointer"
                            />&nbsp;
                            <span>Select country names in the graph to filter results</span>
                        </div>
                        
                    </div>
                </div>
                <div class="guide-info">
                    <h3>Key</h3>
                    <table class="figure-timeline-key">
                        <tbody>
                            <tr>
                                <th>Yes</th>
                                <th>Arms transfers took place?</th>
                                <th>No</th>
                            </tr>
                            <tr class="intensity-war">
                                <td><span class="transfer" /></td>
                                <td>Recipient at <strong>War</strong></td>
                                <td><span class="no-transfer" /></td>
                            </tr>
                            <tr class="intensity-mac">
                                <td><span class="transfer" /></td>
                                <td>Recipient in<br><strong>Minor Armed Conflict</strong></td>
                                <td><span class="no-transfer" /></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="guide-notes">
                    <p>*The <a href="/methodology#tiv">SIPRI Trend Indicator Value</a> (TIV) is a relative measure of arms transfer value, normalized for inflation and currency.</p>
                </div>
            </div>
        </section>
        
        <section
            class="suppliers content"
            v-if="supplierParticipants"
        >
            <div class="left">
                <h3>Conflict Participants</h3>
            </div>
            <div class="right">
                <scroll-top />
            </div>
            
            <div class="left">
                <h4>Participating arms suppliers</h4>
            </div>
            <div class="right">
                <div>
                    <p>The following top arms exporters participated directly in the conflict:</p>
                    <ul class=inline>
                        <li v-for="(label, key) in supplierParticipants" v-bind:key="key" ><router-link v-bind:to="'/supplier/' + key">{{label}}</router-link></li>
                    </ul>
                </div>
            </div>

            <template v-if="fullParticipants()">
                <div class="left">
                    <h4>Full list of participants</h4>
                </div>
                <div class="right">
                    <ul class="inline">
                        <li v-for="(label, slug) in fullParticipants()" v-bind:key="slug" >{{label}}</li>
                    </ul>
                </div>
            </template>
        </section>

        <section class="suppliers content" id="suppliers">
            <div class="left">
                <h3>Other Significant Suppliers</h3>
            </div>
            <div class="right">
                <scroll-top />
                <div v-if="supplierConflict.other">
                    <p>The following top arms exporters did not supply <a href="/methodology#mcw">Major Conventional Weapons</a> to active participants, but did supply significant other types of military goods and services. These transfers were not included in the analysis above but are shown here for context.</p>
                    <div v-if="supplierConflict.other">
                        <p><a href="/methodology#other">Other Significant Suppliers</a> to active participants in this conflict:</p>
                        <ul class="inline">
                            <li
                                v-for="key in supplierConflict.other"
                                v-bind:key="key"
                            >
                                <router-link
                                    v-bind:to="'/supplier/' + key"
                                >{{stateList[key].label}}</router-link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div v-else>
                    <p>There were no <a href="/methodology#other">Other Significant Suppliers</a> to active participants in this conflict.</p>
                </div>
            </div>
            
        </section>

        <section
            class="references content"
            id="references"
            v-if="conflict.references"
        >
            <div class="left">
                <h3>References</h3>
            </div>
            
            <div class="right">
                <scroll-top />
                <ul>
                    <li v-for="reference in conflict.references" v-bind:key="reference.url" >{{reference.source}} : <a v-bind:href="reference.url" target="_blank">{{reference.title}}</a></li>
                </ul>
            </div>
        </section>

    </div>
</template>

<script>
 import _ from 'lodash';
 import common from '../common.js';
 import NavSiblings from './NavSiblings.vue';
 import FigureTransfers from './FigureTransfers.vue';
 import FigureTimeline from './FigureTimeline.vue';

 var defaultSupplier = "All top 11 arms suppliers";
 var defaultParticipant = "All conflict participants";

 export default {
     metaInfo() {
        return { 
            title: `${this.conflict.label || this.conflict.title} | Who Arms War?`,
        };
     },
     props: {
         appConstant: Object,
         siblingList: Array,
         conflictList: Object,
         stateList: Object,
         supplierList: Array,
         supply: Array,
     },
     data () {
         return {
             conflictKey: null,
             conflict: null,

             contents: null,

             transferData: null,
             
             supplier: null,
             participant: null,
             selection: null,
             total: undefined,
             
             participantsNoWarTransfers: [],
         }
     },
     components: {
         NavSiblings,
         FigureTransfers,
         FigureTimeline,
     },
     methods: {
         supplierName () {
             return this.supplier && this.stateList[this.supplier].label || defaultSupplier;
         },
         
         participantName () {
             return this.participant && this.stateList[this.participant].label || defaultParticipant;
         },
         
         hasTransfers () {
             return !_.isNull(this.total);
         },
         
         warPeriod () {
             var cnt = this;
             if (cnt.conflict.warStart == cnt.conflict.warEnd) {
                 return "" + cnt.conflict.warStart;
             }
             return "" + cnt.conflict.warStart + "-" + cnt.conflict.warEnd;
         },
         
         setSelection (supplier, participant) {
             var cnt = this;
             var selectionFrom = cnt.selection;
             var selectionTo = common.selectionKey(supplier, participant)
             
             if (selectionTo == selectionFrom) {
                 return;
             }

             cnt.supplier = supplier;
             cnt.participant = participant;
             cnt.total = common.formatAbbreviate(
                 cnt.transferData.total[common.selectionKey(supplier, participant)] || 0
             );
             cnt.selection = selectionTo;

             if (!_.isNil(selectionFrom)) {
                 // Don't update URL when setting initial selection.
                 var query = {};
                 if (supplier) {
                     query.supplier = supplier;
                 }
                 if (participant) {
                     query.participant = participant;
                 }
                 cnt.$router.push({
                     path: "/conflict/" + cnt.conflictKey,
                     query: query,
                 });
             }
         },

         update (route) {
             var cnt = this;

             var currentIdentity = JSON.stringify([cnt.conflictKey, cnt.supplier, cnt.participant]);
             var routeIdentity = JSON.stringify([route.params.conflict, route.query.supplier, route.query.participant]);

             if (routeIdentity == currentIdentity) {
                 return;
             }

             cnt.conflictKey = route.params.conflict
             cnt.conflict = cnt.conflictList[cnt.conflictKey];

             cnt.suppliers = {};
             cnt.supplierParticipants = {};

             cnt.participantsNoWarTransfers = [];

             var fullParticipants = cnt.fullParticipants();

             _.each(cnt.conflict.participants, function (v) {
                 if (_.includes(cnt.supplierList, v.slug)) {
                     cnt.supplierParticipants[v.slug] = cnt.stateList[v.slug].label;
                 }
                 var match = _.find(cnt.conflict.supplyList, function (supply) {
                     return supply.p.slug == v.slug && supply.w && _.includes(cnt.supplierList, supply.s.slug);
                 });
                 if (!match && !_.has(fullParticipants, v.slug)) {
                     cnt.participantsNoWarTransfers.push(v.slug);
                 }
             });

             _.each(cnt.conflict.supplyList, function (v) {
                 if (v.v) {
                     cnt.suppliers[v.s.slug] = cnt.stateList[v.s.slug].label;
                 }
             });
             var pSlugs = _.map(cnt.conflict.participants, "slug");
             var supplierTotals = {};
             cnt.supplierConflict = {
                 "mcw": [],
                 "other": [],
             };
             _.each(cnt.supplierList, function (supplierSlug) {
                 supplierTotals[supplierSlug] = {
                     "v": 0, // Total TIV value at MAC or War
                     "w": 0, // Total TIV value at War
                     "1": 0, // Total TIV in “War period” or MAC or WAR between 1990 and 1999 inclusive
                     "2": 0, // Total TIV at War from 2000 onward
                     "f": 0, // Total financial value at War
                     "m": 0, // Max financial value in a single year at War
                 };
                 _.each(cnt.conflict.supplyList, function (supplyData) {
                     if (supplyData.s.slug != supplierSlug) {
                         return;
                     }
                     if (!_.includes(pSlugs, supplyData.p.slug)) {
                         return;
                     }

                     supplierTotals[supplierSlug].v += supplyData.v;
                     supplierTotals[supplierSlug].w += supplyData.w;
                     supplierTotals[supplierSlug]["1"] += supplyData["1"];
                     supplierTotals[supplierSlug]["2"] += supplyData["2"];
                     supplierTotals[supplierSlug].f += supplyData.f;
                     supplierTotals[supplierSlug].m = (
                         Math.max(supplierTotals[supplierSlug].m, supplyData.m));
                 });
             });
             _.each(supplierTotals, function (totalData, supplierSlug) {
                 var supplyLevel = common.conflictSupplyLevel(totalData, cnt.conflict.orders, supplierSlug);
                 if (supplyLevel) {
                     cnt.supplierConflict[supplyLevel].push(supplierSlug);
                 }
             });

             if (_.isEmpty(cnt.supplierConflict.mcw)) {
                 cnt.supplierConflict.mcw = null
             }
             if (_.isEmpty(cnt.supplierConflict.other)) {
                 cnt.supplierConflict.other = null
             }
             
             cnt.suppliers = _.isEmpty(cnt.suppliers) ? null : cnt.suppliers;
             cnt.supplierParticipants = _.isEmpty(cnt.supplierParticipants) ? null : cnt.supplierParticipants;


             cnt.transferData = common.getTransferData(cnt.conflict, cnt.supplierList, cnt.stateList);

             
             
             cnt.setSelection(
                 route.query.supplier,
                 route.query.participant,
             );

             cnt.contents = {
                 "transfers": "Weapons transfers to participants",
                 "timeline": "Conflict timeline",
                 "participants": "Conflict participants",
                 "suppliers": "Suppliers",
                 "references": "References",
             }
             if (_.isNil(cnt.supplierParticipants)) {
                 delete cnt.contents.participants;
             }

             if (_.isNil(cnt.conflict.references)) {
                 delete cnt.contents.references;
             }
         },

         fullParticipants () {
             var cnt = this;
             var out = {};
             if (_.isNil(cnt.conflict.showParticipants)) {
                 return null;
             }
             _.each(cnt.conflict.participants, function (v) {
                 out[v.slug] = cnt.stateList[v.slug].label;
             });
             
             return _.isEmpty(out) ? null : out;
         }
     },
     mounted () {
         this.update(this.$route);
     },
     beforeRouteUpdate (to, from, next) {
         this.update(to);
         next();
     },
 };
</script>


<style lang="scss">
</style>
