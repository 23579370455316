<template>
    <div class="figure figure-recipients">
        <svg
            class="figure figure-recipients"
            ref="t1"
            preserveAspectRatio="xMinYMin meet"
            v-bind:viewBox="svgViewbox()"
        >
        </svg>
    </div>
</template>

<script>
 /* eslint no-unused-vars: ["error", { "argsIgnorePattern": "_$" }] */

 import _ from 'lodash';
 import * as d3 from 'd3';
 import common from '../common.js';

 const defaultMargin = 8;

 export default {
     props: {
         supplyTotals: Array,
     },
     data () {
         return {
             svg: {
                 width: 384,
                 top: defaultMargin,
                 right: defaultMargin,
                 bottom: defaultMargin,
                 left: defaultMargin,
                 labelWidth: 60,
                 totalWidth: 60,
                 textHeight: 13,
                 textMargin: 6,
                 titleHeight: 15,
                 titleMargin: 15,
                 dotStroke: 1,
                 
                 height: null,
                 timeLeft: null,
                 timeWidth: null,
                 totalLeft: null,
                 yearBottom: null,

                 yearWidth: null,
                 dotRadius: null,

                 rowHeight: null,
               },
             d3: {
                 svg: null,
             },
             
             nYears: null,
             startYear: null,
             maxTransfer: null,
         };
     },
     watch: {
         supplyTotals () {
             this.update();
         },
     },
     methods: {
         svgViewbox () {
             var cnt = this;
             return "0 0 " + (cnt.svg.width) + " " + (cnt.svg.height || 0);
         },

         createSvg () {
             var cnt = this;
             cnt.svg.timeLeft = cnt.svg.left + cnt.svg.labelWidth + defaultMargin;
             cnt.svg.totalLeft = cnt.svg.width - cnt.svg.right - cnt.svg.labelWidth;
             cnt.svg.timeWidth = cnt.svg.totalLeft - cnt.svg.timeLeft - defaultMargin;

             cnt.svg.yearWidth = cnt.svg.timeWidth / cnt.nYears;
             cnt.svg.dotRadius = cnt.svg.yearWidth * 0.7 / 2 - cnt.svg.dotStroke / 2;

             cnt.svg.rowHeight = cnt.svg.textHeight + cnt.svg.textMargin;

             cnt.svg.height = (
                 cnt.svg.top +
                 cnt.svg.rowHeight * cnt.supplyTotals.length +
                 cnt.svg.textHeight +  // Year labels
                 cnt.svg.bottom
             );

             cnt.svg.yearBottom = cnt.svg.height - cnt.svg.bottom;
             
             var fail = false;
             _.each(cnt.svg, function (v, k) {
                 if (_.isNaN(v) || !_.isNumber(v)) {
                     console.error("SVG value is not a number:", k);
                     fail = true;
                 }
             });
             if (fail) {
                 return;
             }

             cnt.d3.svg = d3.select(cnt.$refs["t1"]);
             cnt.d3.svg.html("");

             cnt.d3.svg
                 .append("rect")
                 .attr("width", cnt.svg.width)
                 .attr("height", cnt.svg.height)
                 .attr("opacity", 0)
             ;
         },

         updateSvg () {
             var cnt = this;

             var yx = i => cnt.svg.timeLeft + (i + 0.5) * cnt.svg.yearWidth;
             var ry = i => cnt.svg.top + (i) * cnt.svg.rowHeight;

             (cnt.d3.svg)
                 .selectAll("text.label")
                 .data(cnt.supplyTotals)
                 .enter()
                 .append("text")
                 .attr("class", "label")
                 .attr("x", cnt.svg.left)
                 .attr("y", (d_, i) => ry(i) + cnt.svg.rowHeight * 0.6)
                 .attr("text-anchor", "start")
                 .attr("font-size", cnt.svg.textHeight * 0.8)
                 .text(d => d.l)
             ;

             (cnt.d3.svg)
                 .selectAll("text.total")
                 .data(cnt.supplyTotals)
                 .enter()
                 .append("text")
                 .attr("class", "total")
                 .attr("x", cnt.svg.width - cnt.svg.right)
                 .attr("y", (d_, i) => ry(i) + cnt.svg.rowHeight * 0.6)
                 .attr("text-anchor", "end")
                 .attr("font-size", cnt.svg.textHeight * 0.8)
                 .text(d => common.formatAbbreviate(d.t) + " TIV")
             ;

             var yearData = [];
             _.each(cnt.supplyTotals, function (v, vi) {
                 _.each(v.y, function (y, yi) {
                     yearData.push({
                         "i": yi - cnt.startYear,
                         "r": vi,
                         "v": y.t,
                     });
                 });
             });

             (cnt.d3.svg)
                 .selectAll("text.year")
                 .data(_.keys(cnt.supplyTotals[0].y))
                 .enter()
                 .append("text")
                 .attr("class", "year")
                 .attr("transform", (d_, i) => common.translate(
                     yx(i),
                     cnt.svg.yearBottom,
                 ))
                 .text((d, i) => (i % 4 == 0 ? d : ""))
                 .attr("font-size", cnt.svg.textHeight * 0.8)
                 .attr("text-anchor", "middle")
             ;

             (cnt.d3.svg)
                 .selectAll("path.value-fill")
                 .data(cnt.supplyTotals)
                 .enter()
                 .append("path")
                 .attr("transform", (d_, i) => common.translate(
                     0,
                     ry(i),
                 ))
                 .attr("class", "value-fill")
                 .attr("d", function (d) {
                     var pt = [];
                     pt.push([yx(0), cnt.svg.textHeight]);
                     _.each(_.values(d.y), function (v, i) {
                         pt.push([yx(i), cnt.svg.textHeight * (1 - ((v.t || 0) / cnt.maxTransfer))]);
                         
                     });
                     pt.push([yx(cnt.nYears - 1), cnt.svg.textHeight]);

                     var p = _(pt).map((v, i) => ((i ? "L" : "M") + v[0] + "," + v[1] + " ")).value();
                     p = p.join("") + "Z";
                     return p;
                 })
             ;

             (cnt.d3.svg)
                 .selectAll("path.value-stroke")
                 .data(cnt.supplyTotals)
                 .enter()
                 .append("path")
                 .attr("transform", (d_, i) => common.translate(
                     0,
                     ry(i),
                 ))
                 .attr("class", "value-stroke")
                 .attr("d", function (d) {
                     var pt = [];
                     _.each(_.values(d.y), function (v, i) {
                         pt.push([yx(i), cnt.svg.textHeight * (1 - ((v.t || 0) / cnt.maxTransfer))]);
                         
                     });

                     var p = _(pt).map((v, i) => ((i ? "L" : "M") + v[0] + "," + v[1] + " ")).value();
                     p = p.join("");
                     return p;
                 })
             ;
         },
         
         update () {
             var cnt = this;

             if (_.isNil(cnt.supplyTotals)) {
                 return;
             }

             cnt.maxTransfer = 0;
             _.each(cnt.supplyTotals, function (v) {
                 cnt.maxTransfer = Math.max(cnt.maxTransfer, v.m);
             });

             cnt.nYears = _.size(cnt.supplyTotals[0].y);
             cnt.startYear = _.keys(cnt.supplyTotals[0].y)[0];
             
             cnt.createSvg();
             cnt.updateSvg();
         },
     },
     mounted () {
         var cnt = this;
         cnt.update();
     },
 };
</script>

<style lang="scss">
</style>
