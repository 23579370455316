<template>
    <div>
        <svg
            xmlns:svg="http://www.w3.org/2000/svg"
            xmlns="http://www.w3.org/2000/svg"
            class="figure figure-transfers"
            ref="t1"
            preserveAspectRatio="xMinYMin meet"
            v-bind:viewBox="svgViewbox()"
            v-bind:class="thumbnail ? 'thumbnail' : ''"
            x="0"
            y="0"
            v-bind:width="svg.width || 1"
            v-bind:height="svg.height || 1"
        >
        </svg>
    </div>
</template>

<script>
 /* eslint no-unused-vars: ["error", { "argsIgnorePattern": "_$" }] */

 import _ from 'lodash';
 import * as d3 from 'd3';
 import { sankey as d3Sankey, sankeyLinkHorizontal as d3SankeyLinkHorizontal } from 'd3-sankey'; 
 import common from '../common.js';

 const defaultMargin = 8;
 const legendLineHeight = 14;
 // Used to set a false Sankey value and ensure a minimum stroke width:
 const minNodeHeight = 3;

 export default {
     props: {
         thumbnail: Boolean,

         transferData: Object,

         supplier: null,
         participant: null,
         selection: null,
         total: null,
     },
     data () {
         return {
             sankey: null,
             graph: null,
             
             svg: {
                 width: 512,
                 height: 384,
                 top: defaultMargin,
                 right: defaultMargin,
                 bottom: defaultMargin,
                 left: defaultMargin,
                 fontFamily: "Aleo",
                 colorParticipant: "#D35E13",
                 colorSupplier: "#3172AE",

                 sankeyWidth: null,
                 sankeyHeight: null,
             },
             d3: {
                 svg: null,
             },


         };
     },
     watch: {
         transferData () {
             this.update();
         },
         selection () {
             this.update();
         },
     },
     methods: {
         svgViewbox () {
             var cnt = this;
             return "0 0 " + (cnt.svg.width) + " " + (cnt.svg.height || 0);
         },
         
         setSelection (supplier, participant) {
             var cnt = this;
             cnt.$emit("set-selection", supplier, participant);

             cnt.updateSvg();
         },

         createSvg () {
             var cnt = this;

             cnt.svg.top = cnt.thumbnail ? defaultMargin : 36;
             cnt.svg.sankeyWidth = cnt.svg.width - cnt.svg.left - cnt.svg.right;
             cnt.svg.sankeyHeight = cnt.svg.height - cnt.svg.top - cnt.svg.bottom;

             if (cnt.transferData.total[common.selectionKey(null, null)] == 0) {
                 cnt.svg.top = cnt.svg.top + cnt.svg.sankeyHeight / 3;
                 cnt.svg.sankeyHeight *= 1 / 3;
             }
             
             cnt.d3.svg = d3.select(cnt.$refs["t1"]);

             cnt.d3.svg.html("");

             var gradient = cnt.d3.svg
                 .append("defs")
                 .append("linearGradient")
                 .attr("id", "participant-to-supplier")
             ;
             gradient
                 .append("stop")
                 .attr("stop-color", cnt.svg.colorSupplier)
                 .attr("offset", "0.0")
             ;
             gradient
                 .append("stop")
                 .attr("stop-color", cnt.svg.colorParticipant)
                 .attr("offset", "0.5")
             ;
             cnt.d3.svg
                 .append("rect")
                 .attr("width", cnt.svg.width)
                 .attr("height", cnt.svg.height)
                 .attr("opacity", 0)
                 .on("click", function (e_) {
                     cnt.setSelection(null, null);
                 })
             ;

             if (!cnt.thumbnail) {
                 cnt.d3.svg
                    .append("text")
                    .attr("x", cnt.svg.left)
                    .attr("y", defaultMargin + legendLineHeight)
                    .attr("text-anchor", "start")
                    .attr("class", "title")
                    .text("Top arms suppliers")
                 ;
                 cnt.d3.svg
                    .append("text")
                    .attr("x", cnt.svg.width / 2)
                    .attr("y", defaultMargin + legendLineHeight * 0.5)
                    .attr("text-anchor", "middle")
                    .attr("class", "title")
                    .text("Relative value of arms transfers")
                 ;
                 cnt.d3.svg
                    .append("text")
                    .attr("x", cnt.svg.width / 2)
                    .attr("y", defaultMargin + legendLineHeight * 1.5)
                    .attr("text-anchor", "middle")
                    .attr("class", "title")
                    .text("to participants at War")
                 ;
                 cnt.d3.svg
                    .append("text")
                    .attr("x", cnt.svg.width - cnt.svg.right)
                    .attr("y", defaultMargin + legendLineHeight)
                    .attr("text-anchor", "end")
                    .attr("class", "title")
                    .text("Conflict participants")
                 ;
                 if (cnt.grandTotal == 0) {
                     cnt.d3.svg
                        .append("text")
                        .attr("x", cnt.svg.width / 2)
                        .attr("y", defaultMargin + legendLineHeight * 3)
                        .attr("text-anchor", "middle")
                        .text("(none)")
                     ;
                 }
             }

             cnt.sankeyG = cnt.d3.svg
                 .append("g")
                 .attr("transform", common.translate(cnt.svg.left, cnt.svg.top))
             ;

             cnt.d3.linkG = cnt.sankeyG.append("g");
             cnt.d3.nodeG = cnt.sankeyG.append("g");
             cnt.d3.tieG = cnt.sankeyG.append("g");
             cnt.d3.labelG = cnt.sankeyG.append("g");

             cnt.sankey = d3Sankey()
                 .nodeWidth(36)
                 .nodePadding(12)
                 .size([cnt.svg.sankeyWidth, cnt.svg.sankeyHeight])
                 .nodeSort(function (a, b) {
                     if (a.transferValue > b.transferValue) {
                         return -1;
                     }
                     if (a.transferValue < b.transferValue) {
                         return 1;
                     }
                     if (a.class.indexOf("supplier-participant") !== -1) {
                         return -1;
                     }
                     if (b.class.indexOf("supplier-participant") !== -1) {
                         return 1;
                     }
                     return 0;
                 })
             ;
             
             cnt.sankey.links();

             cnt.graph = cnt.sankey(cnt.transferData);

             _.each(cnt.graph.nodes, function (d) {
                 var height = d.y1 - d.y0;
                 if (height < minNodeHeight) {
                     d.y1 = d.y0 + minNodeHeight;
                     d.y0 -= (minNodeHeight - height) / 2;
                 }
             });

             if (cnt.thumbnail) {
                 (cnt.d3.svg)
                     .append("rect")
                     .attr("class", "tint")
                     .attr("width", cnt.svg.width)
                     .attr("height", cnt.svg.height)
                 ;
             }
         },
         
         updateSvg () {
             var cnt = this;
             
             if (_.isNil(cnt.sankeyG)) {
                 return;
             }

             var link = (cnt.d3.linkG)
                 .selectAll(".link")
                 .data(cnt.graph.links, d => d.index)
             ;

             var linkEnter = link
                 .enter()
                 .append("path")
                 .attr("d", d3SankeyLinkHorizontal())
                 .attr("stroke-width", d => d.transferValue ? d.width : 0)
                 .on("touchstart", function (e, d) {
                     // Sort elements to put this one on top.
                     cnt.sankeyG.selectAll(".link").sort((a, b_) => (a.index == d.index) ? 1 : -1);
                     cnt.setSelection(d.source.slug, d.target.slug);
                 })
                 .on("mouseover", function (e, d) {
                     // This consumes the first click instead of `click` on touch devices.
                     
                     // Sort elements to put this one on top.
                     cnt.sankeyG.selectAll(".link").sort((a, b_) => (a.index == d.index) ? 1 : -1);
                 })
                 .on("click", function (e, d) {
                     cnt.setSelection(d.source.slug, d.target.slug);
                 })
             ;

             linkEnter
                 .merge(link)
                 .attr("class", function (d) {
                     var c = ["link"];
                     var s = d.source.slug;
                     var p = d.target.slug;
                     d.selected = (
                         (cnt.supplier == s && cnt.participant == p) ||
                         (cnt.supplier == s && cnt.participant == null) ||
                         (cnt.supplier == null && cnt.participant == p)
                     );
                     if (d.selected) {
                         c.push("selected");
                     }
                     return c.join(" ");
                 })
                 .sort((a, b_) => a.selected ? 1 : -1)
             ;
             
             link.exit().remove();

             var arrow = {};
             
             cnt.d3.linkG.selectAll("path.link").each(function () {
                 var node = d3.select(this).node();
                 var p = node.getBBox();
                 if (_.isNil(arrow.y) || p.y < arrow.y) {
                     arrow.y = p.y;
                     arrow.strokeWidth = node.getAttribute("stroke-width");
                     arrow.x1 = p.x + p.width * (0.5 - 0.05);
                     arrow.x2 = p.x + p.width * (0.5 + 0.05);
                 }
             });

             if (_.size(arrow)) {
                 arrow.y1 = arrow.y - 6;
                 arrow.y2 = arrow.y - 1;
                 arrow.y3 = arrow.y + 1;
                 arrow.y4 = arrow.y + 6;
                 arrow.x3 = arrow.x2 - 12;

                 var points = [
                     [arrow.x1, arrow.y2],
                     [arrow.x3, arrow.y2],
                     [arrow.x3, arrow.y1],
                     [arrow.x2, arrow.y],
                     [arrow.x3, arrow.y4],
                     [arrow.x3, arrow.y3],
                     [arrow.x1, arrow.y3],
                 ];

                 var pointsS = _.map(points, v => v.join(",")).join(" ");

                 (cnt.d3.linkG)
                     .append("polygon")
                     .attr("class", "arrow")
                     .attr("points", pointsS)
                 ;
             }

             var node = (cnt.d3.nodeG)
                 .selectAll(".node")
                 .data(cnt.graph.nodes)
             ;
             var nodeEnter = node
                 .enter()
                 .append("g")
             ;

             var nodeLabel = (cnt.d3.labelG)
                 .selectAll(".nodeLabel")
                 .data(cnt.graph.nodes)
             ;
             var nodeLabelEnter = nodeLabel
                 .enter()
                 .append("g")
             ;

             nodeEnter
                 .append("rect")
                 .attr("x", d => d.x0)
                 .attr("y", d => d.y0)
                 .attr("height", d => d.y1 - d.y0)
                 .attr("width", cnt.sankey.nodeWidth())
                 .on("click", function (e, d) {
                     if (d.class == "supplier") {
                         cnt.setSelection(d.slug, null);
                     } else {
                         cnt.setSelection(null, d.slug);
                     }
                 })
             ;

             nodeLabelEnter
                 .append("text")
                 .attr("class", "shadow")
                 .attr("y", d => (d.y1 + d.y0) / 2)
                 .attr("x", d => d.x0 - 6)
                 .attr("dy", "0.35em")
                 .attr("font-family", cnt.svg.fontFamily)
                 .attr("text-anchor", "end")
                 .text(d => d.name)
                 .filter(d => d.x0 < cnt.svg.sankeyWidth / 2)
                 .attr("x", d => d.x1 + 6)
                 .attr("text-anchor", "start")
             ;
             nodeLabelEnter
                 .append("text")
                 .attr("y", d => (d.y1 + d.y0) / 2)
                 .attr("x", d => d.x0 - 6)
                 .attr("dy", "0.35em")
                 .attr("font-family", cnt.svg.fontFamily)
                 .attr("text-anchor", "end")
                 .text(d => d.name)
                 .filter(d => d.x0 < cnt.svg.sankeyWidth / 2)
                 .attr("x", d => d.x1 + 6)
                 .attr("text-anchor", "start")
             ;

             nodeEnter
                 .merge(node)
                 .attr("class", function (d) {
                     var c = ["node", d.class];
                     if (!d.transferValue) {
                         c.push("zero");
                     }
                     if (d.node == 0) {
                         c.push("fake");
                     }
                     if (d.class.indexOf("supplier") == 0 && cnt.supplier == d.slug) {
                         c.push("selected");
                     } else if (d.class.indexOf("participant") == 0 && cnt.participant == d.slug) {
                         c.push("selected");
                     }
                     return c.join(" ");
                 })
             
             node.exit().remove();
             nodeLabel.exit().remove();

             var supplierParticipantMatch = {};

             cnt.d3.nodeG.selectAll("g.supplier").each(function (d) {
                 var node = d3.select(this).select("rect").node();
                 var p = node.getBBox();
                 supplierParticipantMatch[d.slug] = {};
                 supplierParticipantMatch[d.slug].sx = p.x + p.width;
                 supplierParticipantMatch[d.slug].sy = p.y;
             });
             cnt.d3.nodeG.selectAll("g.participant").each(function (d) {
                 var node = d3.select(this).select("rect").node();
                 var p = node.getBBox();
                 if (!_.isNil(supplierParticipantMatch[d.slug])) {
                     supplierParticipantMatch[d.slug].px = p.x;
                     supplierParticipantMatch[d.slug].py = p.y;
                 }
             });

             _.each(supplierParticipantMatch, function (v) {
                 var sw = 3;
                 if (_.isNil(v.px)) {
                     return;
                 }
                 points = [
                     "M",
                     v.sx, v.sy + sw / 2,
                     "C",
                     (v.sx * 2 + v.px * 1) / 3, v.sy + sw / 2,
                     (v.sx * 1 + v.px * 2) / 3, v.py + sw / 2,
                     v.px, v.py + sw / 2,
                 ]
                 
                 cnt.d3.tieG
                    .append("path")
                    .attr("d", points.join(" "))
                    .attr("y1", v.sy)
                    .attr("x2", v.px)
                    .attr("y2", v.py)
                    .attr("class", "supplier-participant")
                    .style("stroke-width", sw)
                 ;
             });
         },
         
         update () {
             var cnt = this;
             
             if (_.isNil(cnt.transferData)) {
                 return;
             }

             cnt.createSvg();
             cnt.updateSvg();
         },
     },
     mounted () {
         var cnt = this;

         cnt.update();
     },
 };
</script>

<style lang="scss">
</style>
