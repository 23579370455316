<template>
    <div>
        <router-link
            class="nav-home"
            to="/"
        ><h3>{{appConstant.siteTitle}}</h3></router-link>
        <a
            class="nav-wpf"
            href="https://sites.tufts.edu/wpf/"
        >
            <img
                v-if="invert"
                alt="World Peace Foundation logo"
                src="../assets/wpf.stacked.white-transparent.376x233.png"
            />
            <img
                v-else
                alt="World Peace Foundation logo"
                src="../assets/wpf.stacked.color-transparent.545x341.png"
            />
        </a>
        <ul class="nav-pages">
            <li><router-link to="/">Key Findings</router-link></li>
            <li><router-link to="/supplier">Top Arms Suppliers</router-link></li>
            <li><router-link to="/conflict">Conflicts</router-link></li>
            <li><router-link to="/methodology">Methodology</router-link></li>
            <li><router-link to="/about">About</router-link></li>
            <li><router-link to="/privacy">Privacy</router-link></li>
        </ul>
    </div>
</template>

<script>
 export default {
     props: {
         appConstant: Object,
         invert: Boolean,
     },
 };
</script>
