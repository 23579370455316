<template>
    <svg
        xmlns:svg="http://www.w3.org/2000/svg"
        xmlns="http://www.w3.org/2000/svg"
        class="figure figure-suppliers"
        ref="t1"
        preserveAspectRatio="xMinYMin meet"
        v-bind:viewBox="svgViewbox()"
        v-bind:class="thumbnail ? 'thumbnail' : ''"
        x="0"
        y="0"
        v-bind:width="svg.width || 1"
        v-bind:height="svg.height || 1"
    >
    </svg>
</template>

<script>
 /* eslint no-unused-vars: ["error", { "argsIgnorePattern": "_$" }] */

 import _ from 'lodash';
 import * as d3 from 'd3';
 import common from '../common.js';

 export default {
     props: {
         thumbnail: Boolean,

         transferData: Object,
     },
     data () {
         return {
             graph: null,
             svg: {
                 elWidthFull: 1024,
                 elWidthThumb: 420,
                 fontFamily: "Aleo",
                 fontSize: 16,
                 defaultMarginPercent: 1,

                 lineHeightMult: null,
                 width: null,
                 height: null,
                 lineHeight: null,
                 defaultMargin: null,
             },
             d3: {
                 svg: null,
             },


         };
     },
     watch: {
         transferData () {
             this.update();
         },
         selection () {
             this.update();
         },
     },
     methods: {
         svgViewbox () {
             var cnt = this;
             return "0 0 " + (cnt.svg.width || 0) + " " + (cnt.svg.height || 0);
         },
         
         setSelection (supplier, participant) {
             var cnt = this;
             cnt.$emit("set-selection", supplier, participant);

             cnt.updateSvg();
         },

         createSvg () {
             var cnt = this;

             var data = [];

             _.each(cnt.transferData, function (supplierData, supplierSlug) {
                 data.push({
                     "slug": supplierSlug,
                     "url": '/supplier/' + supplierSlug,
                     "name": supplierData.name,
                     "supply": supplierData.value,
                     "supplySqrt": Math.sqrt(supplierData.value),
                     "recipient": supplierData.recipientCount,
                     "conflict": supplierData.conflictCount,
                 });
             });

             _.each(data, function (v) {
                 if (v.slug == "ukraine") {
                     v.name = "Ukr.";
                 }
                 if (v.slug == "germany") {
                     v.name = "Ger.";
                 }
                 if (v.slug == "israel") {
                     v.name = "Isr.";
                 }
                 if (v.slug == "spain") {
                     v.name = "Spa.";
                 }
             });
             
             var totalSupplySqrt = _(data).map("supplySqrt").sum();

             data = _.sortBy(data, function (v) {return -v.supply});

             cnt.svg.width = cnt.thumbnail ? cnt.svg.elWidthThumb : cnt.svg.elWidthFull;

             cnt.svg.lineHeightMult = cnt.thumbnail ? 1.25 : 1.75;

             cnt.svg.defaultMargin = cnt.svg.width * cnt.svg.defaultMarginPercent / 100;
             cnt.svg.lineHeight = cnt.svg.fontSize * cnt.svg.lineHeightMult;

             cnt.svg.graphWidth = cnt.svg.width - 2 * cnt.svg.defaultMargin;
             cnt.svg.graphHeight = cnt.svg.graphWidth / 5;
             cnt.svg.graphX = cnt.svg.defaultMargin;
             cnt.svg.graphY = cnt.svg.defaultMargin;

             cnt.svg.legendLines = cnt.thumbnail ? 2 : 4.25;
             cnt.svg.legendY = cnt.svg.graphY + cnt.svg.graphHeight + cnt.svg.defaultMargin;
             cnt.svg.legendHeight = cnt.svg.legendLines * cnt.svg.lineHeight;

             cnt.svg.marginSq = cnt.svg.defaultMargin * 2;
             cnt.svg.scaleSq = (cnt.svg.graphWidth - cnt.svg.marginSq * data.length) / totalSupplySqrt;
             cnt.svg.height = cnt.svg.legendY + cnt.svg.legendHeight + cnt.svg.defaultMargin;

             var sqLeft = cnt.svg.graphX;
             _.each(data, function (v) {
                 sqLeft += cnt.svg.marginSq / 2;
                 v.left = sqLeft;
                 v.sq = cnt.svg.scaleSq * v.supplySqrt;
                 sqLeft += v.sq;
                 sqLeft += cnt.svg.marginSq / 2;
             });

             cnt.d3.svg = d3.select(cnt.$refs["t1"]);
             cnt.d3.svg.html("");

             cnt.d3.svg
                 .append("rect")
                 .attr("class", "layout")
                 .attr("x", cnt.svg.graphX)
                 .attr("y", cnt.svg.graphY)
                 .attr("width", cnt.svg.graphWidth)
                 .attr("height", cnt.svg.graphHeight)
             ;
             
             cnt.d3.svg
                 .append("rect")
                 .attr("class", "layout")
                 .attr("x", cnt.svg.graphX)
                 .attr("y", cnt.svg.legendY)
                 .attr("width", cnt.svg.graphWidth)
                 .attr("height", cnt.svg.legendHeight)
             ;
             
             var itemEnter = (cnt.d3.svg)
                 .selectAll("a")
                 .data(data)
                 .enter()
                 .append("a")
                 .attr("href", d => d.url)
                 .attr("transform", d => common.translate(
                     d.left,
                     cnt.svg.graphY + cnt.svg.graphHeight
                 ))
             ;
             
             itemEnter
                 .append("rect")
                 .attr("class", "shadow")
                 .attr("y", -5)
                 .attr("width", d => d.sq)
                 .attr("height", 5)
             ;
             
             itemEnter
                 .append("rect")
                 .attr("class", "bar")
                 .attr("y", d => -d.sq)
                 .attr("width", d => d.sq)
                 .attr("height", d => d.sq)
             ;
             
             itemEnter
                 .append("text")
                 .attr("class", "legend name linkTitle")
                 .attr("x", d => d.sq / 2)
                 .attr("y", (d, i) => cnt.svg.defaultMargin + cnt.svg.lineHeight * (0.5 + (cnt.thumbnail ? (i % 2) : 0)))
                 .attr("font-family", cnt.svg.fontFamily)
                 .attr("text-anchor", "middle")
                 .text(d => d.name)
             ;
             
             if (!cnt.thumbnail) {
                 itemEnter
                     .append("text")
                     .attr("class", "sub-legend supply")
                     .attr("x", d => d.sq / 2)
                     .attr("y", cnt.svg.lineHeight * 2.25)
                     .text((d, i) => "" + common.formatAbbreviate(d.supply) + (i ? "" : " TIV*"))
                 ;
                 
                 itemEnter
                     .append("text")
                     .attr("class", "sub-legend recipient")
                     .attr("x", d => d.sq / 2)
                     .attr("y", cnt.svg.lineHeight * 3.25)
                     .text((d, i) => "" + d.recipient + (i ? "" : " recipients"))
                 ;
                 
                 itemEnter
                     .append("text")
                     .attr("class", "sub-legend conflict")
                     .attr("x", d => d.sq / 2)
                     .attr("y", cnt.svg.lineHeight * 4.25)
                     .text((d, i) => "" + d.conflict + (i ? "" : " conflicts"))
                 ;
             }
             
             itemEnter
                 .append("rect")
                 .attr("class", "clickTarget")
                 .attr("x", -cnt.svg.marginSq / 2)
                 .attr("y", -(cnt.svg.graphY + cnt.svg.graphHeight))
                 .attr("width", d => d.sq + cnt.svg.marginSq)
                 .attr("height", cnt.svg.height)
             ;
             
             (cnt.d3.svg)
                 .append("line")
                 .attr("x1", cnt.svg.graphX)
                 .attr("y1", cnt.svg.graphY + cnt.svg.graphHeight)
                 .attr("x2", cnt.svg.graphX + cnt.svg.graphWidth)
                 .attr("y2", cnt.svg.graphY + cnt.svg.graphHeight)
             ;

             if (cnt.thumbnail) {
                 (cnt.d3.svg)
                     .append("rect")
                     .attr("class", "tint")
                     .attr("width", cnt.svg.width)
                     .attr("height", cnt.svg.height)
                 ;
             }
         },
         
         update () {
             var cnt = this;
             
             if (_.isNil(cnt.transferData)) {
                 return;
             }

             cnt.createSvg();
         },
     },
     mounted () {
         var cnt = this;

         cnt.update();
     },
 };
</script>

<style lang="scss">
</style>
